import React, { useContext, useState } from 'react';
//import { DateTime } from 'luxon';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { ImportExport  } from '@material-ui/icons';
import { CircularProgress, Snackbar, Tooltip  } from '@mui/material';

const ExportDataByGroups = ({ filterDate, byMonth }) => {
  const fetchContext = useContext(FetchContext);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display

  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    const dataArr = [];
    const finalPayload = {
      ...filterDate,
      export: true,
      date: byMonth,
    };

    await fetchContext.authAxios
      .post(`/get-report-by-charger-group`, finalPayload)
      .then(({ data }) => {
        const pdata = data.data;

        if (pdata) {
          for (let item of pdata) {
            //dataObj.sessionID = item.id;
            let obj = {
              charger_group_name: item.charger_group_name,
              site: item?.site_name,
              amount: Number(item.amount).toFixed(2),
              energy: item?.energy ? Number(item?.energy).toFixed(4) : "0.0000",
              // item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : '-'
              duration: item?.timezone_duration
                ? (item.timezone_duration / 60 / item.total).toFixed(2)
                : "-",
              total: item.total,
              amount: Number(item.amount).toFixed(2),
              address: item.address?.replace(/,/g, ""),
              city: item.city,
              state: item.state,
              postal_code: item.postal_code,
              latitude: item.latitude,
              longitude: item.longitude,
            };
            dataArr.push(obj);
          }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); // Show message when starting download
        }
      });
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "charger_group_name", displayName: "Charger Group Name" },
    // { id: "site", displayName: "Site Name" },
    { id: "amount", displayName: "Amount (USD)" },
    { id: "energy", displayName: "Energy Delivered (KWH)" },
    { id: "duration", displayName: "Average Charging Duration (Minutes)" },
    { id: "total", displayName: "Number of Sessions" },
    // { id: "address", displayName: "Address" },
    // { id: "city", displayName: "City" },
    // { id: "state", displayName: "State" },
    // { id: "postal_code", displayName: "Zipcode" },
    // { id: "latitude", displayName: "Latitude" },
    // { id: "longitude", displayName: "Longitude" },
  ];

  return (
    <>
      <CsvDownloader
        filename="ChargerGroupDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport style={{ fill: "#fff" }}/>}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default ExportDataByGroups;
