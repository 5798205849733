import {
  FormLabel,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import { socketFetch } from "../../services/socket";
import Confirm from "../ChargingStations/Confirm";
import SitesSelect from "../Sites/SitesSelect";
import ChargingPeriod from "./ChargingPeriod";
import ChargingStationSelect from "./ChargingStationsSelect";
import SiteEVSERadioGroup from "./SiteEVSERadioGroup";
import { useIsDesktop } from "../../context/DisplayContext";
import LimitTypeSelect from "./LimitTypeSelect";
import SiteChargingStationSelect from "./SiteChargingStationSelect";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

function formatDateToISOWithMidnight(date) {
  if (!(date instanceof Date)) {
    throw new Error("Input is not a valid JavaScript Date object");
  }

  const luxonDate = DateTime.fromJSDate(date);
  const midnightDate = luxonDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const isoFormattedDate = midnightDate.toISO({ includeOffset: false }) + "Z";
  return isoFormattedDate;
}

const calculateSecondsFromMidnight = (specificTime) => {
  const startTimeHours = specificTime.getHours();
  const startTimeMinutes = specificTime.getMinutes();
  const startTimeSeconds = specificTime.getSeconds();
  const startTimeMillis = specificTime.getMilliseconds();

  const secondsDifference =
    startTimeHours * 3600 +
    startTimeMinutes * 60 +
    startTimeSeconds +
    Math.round(startTimeMillis / 1000);
  return secondsDifference;
};

const areTimesEqual = (time1, time2) => {
  const luxonTime1 = DateTime.fromJSDate(time1);
  const luxonTime2 = DateTime.fromJSDate(time2);

  return luxonTime1.equals(luxonTime2);
};

const areTimesUpDown = (t1, t2) => {
  const t1hours = t1.hour;
  const t1mins = t1.minute;
  const t1seconds = t1.second;

  const t2hours = t2.hour;
  const t2mins = t2.minute;
  const t2seconds = t2.second;

  if (t1hours > t2hours) {
    return true;
  } else if (t1hours === t2hours) {
    if (t1mins > t2mins) {
      return true;
    } else if (t1mins === t2mins) {
      if (t1seconds > t2seconds) {
        return true;
      }
    }
  }

  return false;
};

const generateChargingSchedulePeriod = (chargingList, scheduledDate) => {
  const responseObject = [];
  const startOfDay = DateTime.fromJSDate(scheduledDate).startOf("day");

  if (chargingList.length === 0) {
    responseObject.push({ startPeriod: 0, limit: 0 });
    return responseObject;
  }

  if (
    areTimesUpDown(startOfDay, DateTime.fromJSDate(chargingList[0].start_time))
  ) {
    responseObject.push({ startPeriod: 0, limit: chargingList[0].limit });
  } else {
    responseObject.push({ startPeriod: 0, limit: 0 });
  }

  chargingList.forEach((item, index) => {
    const startPeriod = calculateSecondsFromMidnight(item.start_time);
    responseObject.push({ startPeriod, limit: parseInt(item.limit) });

    if (index < chargingList.length - 1) {
      const nextItem = chargingList[index + 1];
      if (!areTimesEqual(item.end_time, nextItem.start_time)) {
        const extraStartPeriod = calculateSecondsFromMidnight(item.end_time);
        responseObject.push({ startPeriod: extraStartPeriod, limit: 0 });
      }
    }
  });

  const lastItem = chargingList[chargingList.length - 1];
  const extraStartPeriod = calculateSecondsFromMidnight(lastItem.end_time);
  responseObject.push({ startPeriod: extraStartPeriod, limit: 0 });

  return Array.from(
    new Set(responseObject?.map((obj) => JSON.stringify(obj)))
  )?.map((str) => JSON.parse(str));
};

const convertToOutputList = (inputList) => {
  return inputList?.map((item, index, array) => {
    const nextItem = array[index + 1];
    const endPeriod = nextItem ? nextItem.startPeriod : 84600; // Use 84600 for last item
    return [item.startPeriod / 3600, endPeriod / 3600, item.limit];
  });
};

const convertToOutputLegendList = (inputList) => {
  return inputList?.map((item, index, array) => {
    const nextItem = array[index + 1];
    const endPeriod = nextItem ? nextItem.startPeriod : 84600; // Use 84600 for last item
    return [
      Math.floor(item.startPeriod / 3600).toString() +
        ":" +
        (
          (item.startPeriod - Math.floor(item.startPeriod / 3600)) /
          60
        ).toString(),
      Math.floor(endPeriod / 3600).toString() +
        ":" +
        ((endPeriod - Math.floor(endPeriod / 3600)) / 60).toString(),
      item.limit,
    ];
  });
};

function validateTimeGaps(entries) {
  for (let i = 0; i < entries.length - 1; i++) {
    const currentEndTime = entries[i].end_time;
    const nextStartTime = entries[i + 1].start_time;

    if (nextStartTime < currentEndTime) {
      return false; // Gap condition not met
    }
  }
  return true; // All gaps satisfy the condition
}

function validateTimeCondition(entries) {
  for (let i = 0; i < entries.length; i++) {
    const startTime = entries[i].start_time;
    const endTime = entries[i].end_time;

    if (startTime >= endTime) {
      return false; // Condition not met for at least one object
    }
  }
  return true; // Condition met for all objects
}

const createLoadSitePayload = (list, values) => {
  if (!list || list.length === 0) {
    return null;
  }

  // Function to format date as YYYY-MM-DDT00:00:00.000Z (Start) or YYYY-MM-DDT23:59:59.999Z (End)
  const formatDate = (date, isStart) => {
    if (!date) return null;
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, "0");
    const dd = String(d.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}T${isStart ? "00:00:00.000" : "23:59:59.999"}Z`;
  };

  // Function to combine date from one field with time from another **without timezone conversion**
  const mergeDateTime = (date, time) => {
    if (!date || !time) return null;

    const d = new Date(date);
    const t = new Date(time);

    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, "0");
    const dd = String(d.getDate()).padStart(2, "0");

    const hh = String(t.getHours()).padStart(2, "0");
    const min = String(t.getMinutes()).padStart(2, "0");
    const sec = String(t.getSeconds()).padStart(2, "0");
    const ms = String(t.getMilliseconds()).padStart(3, "0");

    return `${yyyy}-${mm}-${dd}T${hh}:${min}:${sec}.${ms}Z`; // Keeps exact entered time
  };
console.log("listlist", list);

  return {
    limit: parseInt(values.max_limit),
    site_id: values.site_id,
    cluster: list?.map((item) => ({
      charger_id: item.charger_id.id,
      max_limit: parseInt(item.limit),
      chargerLimit: item?.charger_id?.charger_output,
    })),
    limit_type: values?.limit_type,
    start_date: values?.start_date ? formatDate(values.start_date, true) : null,
    end_date: values?.end_date ? formatDate(values.end_date, false) : null,
    start_time: mergeDateTime(values.start_date, values.start_time),
    end_time: mergeDateTime(values.end_date, values.end_time),
  };
};

const LoadSharing = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [siteEvse, setSiteEvse] = useState("1");
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [chargingSchedulePeriod, setChargingSchedulePeriod] = useState([]);
  const [chargingSchedulePeriodGraphData, setChargingSchedulePeriodGraphData] =
    useState([]);
  const [
    chargingSchedulePeriodLegendGraphData,
    setChargingSchedulePeriodLegendGraphData,
  ] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [gapError, setGapError] = useState("");
  const [dateCompareError, setDateCompareError] = useState(false);
  const [startdateCompareError, setstartdateCompareError] = useState(false);
  const [timeCompareError, setTimeCompareError] = useState(false);
  const [selected, setSelected] = useState("");
  const [maxLimit, setMaxLimit] = useState(1000);
  const [connector, setconnector] = useState(0);
  const [options, setOptions] = useState([]);

  const { isDesktop } = useIsDesktop();
  const [payload, setPayload] = useState({});

  const abortController = useRef(new AbortController());

  const [chargingPeriodList, setChargingPeriodList] = useState([
    {
      limit: null,
      start_time: null,
      end_time: null,
    },
  ]);

  const [siteProfileList, setSiteProfileList] = useState([
    {
      limit: null,
      charger_id: null,
    },
  ]);

  let headCells = [
    { id: "charging_profile_id", label: "Charging Profile ID" },
    { id: "charger_id", label: "Charger ID" },
    { id: "charging_schedule_period", label: "Charging Schedule Period" },
  ];
  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  useEffect(() => {
    if (connector === 0) {
      setOptions([]);
    }
    if (connector === 1) {
      setOptions([{ id: 1, name: 1 }]);
    } else if (connector === 2) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
      ]);
    } else if (connector === 3) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
      ]);
    } else if (connector === 4) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
      ]);
    }
  }, [connector]);

  const getconnector = async (id) => {
    if (id) {
      fetchContext.authAxios
        .post(`/get-number-of-connectors`, { id: id })
        .then(({ data }) => {
          if (data?.data > 0) {
            setconnector(data?.data);
            if (data?.data === 0) {
              setOptions([]);
            } else if (data?.data === 1) {
              setOptions([{ id: 1, name: 1 }]);
            } else if (data?.data === 2) {
              setOptions([
                { id: 1, name: 1 },
                { id: 2, name: 2 },
              ]);
            } else if (data?.data === 3) {
              setOptions([
                { id: 1, name: 1 },
                { id: 2, name: 2 },
                { id: 3, name: 3 },
              ]);
            } else if (data?.data === 4) {
              setOptions([
                { id: 1, name: 1 },
                { id: 2, name: 2 },
                { id: 3, name: 3 },
                { id: 4, name: 4 },
              ]);
            }
          }
        });
    }
  };

  const getAllProfiles = async (fetchContext) => {
    try {
      const { data } = await fetchContext.authAxios.get(
        "/select-charging-stations"
      );
      setLoaded(true);
      setProfiles(data.data.data);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const { TblContainer, TblHead } = useTable(headCells);

  const initialFValues = {
    start_schedule: "",
  };

  const validate = async (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (type, payload) => {
    let path = "/setChargingProfile";
    if (type) {
      path = "/clearChargingProfile";
    }

    return socketFetch
      .post(path, payload)
      .then(({ data }) => {
        if (abortController.current.signal.aborted) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
          return;
        }

        setLoaded(false);
        getAllProfiles(fetchContext);
        setNotify({
          isOpen: true,
          message: data.message,
          type: "success",
        });
        setChargingPeriodList([{ limit: 0, start_time: null, end_time: null }]);
        setSiteProfileList([
          {
            limit: "",
            charger_id: null,
          },
        ]);
        setValues({
          connector_id: null,
          charger_id: null,
          start_date: null,
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again: ${err.message}`,
          type: "error",
        });
        if (abortController.current.signal.aborted) {
          return;
        }
      });
  };

  const handleSubmitLoadSite = async () => {
    let path = "/setLoadSharing";

    let payloadData = createLoadSitePayload(siteProfileList, values);

    return socketFetch
      .post(path, payloadData)
      .then(({ data }) => {
        if (abortController.current.signal.aborted) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
          return;
        }

        setLoaded(false);
        getAllProfiles(fetchContext);
        setNotify({
          isOpen: true,
          message: data.result,
          type: "success",
        });
        setChargingPeriodList([{ limit: 0, start_time: null, end_time: null }]);
        setSiteProfileList([
          {
            limit: "",
            charger_id: null,
          },
        ]);
        setValues({
          connector_id: null,
          charger_id: null,
          start_date: null,
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `There is some issue, please try again: ${err.message}`,
          type: "error",
        });
        if (abortController.current.signal.aborted) {
          return;
        }
      });
  };

  const handleSiteEVSE = (event) => {
    setSiteEvse(event.target.value);
  };

  const handlePeriodChange = (event, index) => {
    let { name, value } = event.target;
    const list = [...chargingPeriodList];
    if (name === "start_time" || name === "end_time") {
      value = new Date(value);
    }

    if (name === "limit") {
      if (value && value > 10000) {
        value = 10000;
      }
      if (value < 0) {
        value = 0;
      }
    }
    list[index][name] = value;
    setChargingPeriodList(list);
  };

  const handlePeriodChangeSite = (event, index, id_charger, charger_output) => {
    let name = event ? event.target.name : null;
    let value = event ? event.target.value : null;

    const list = [...siteProfileList];

    if (name === "limit") {
      if (value && value > maxLimit) {
        value = maxLimit;
      }
      if (value < 0) {
        value = 0;
      }
    }

    if (!name || name === "charger_id") {
      list[index]["charger_id"] = {
        id: id_charger,
        label: id_charger,
        charger_output: charger_output,
      };
    } else list[index][name] = value;
    // console.log(list)
    setSiteProfileList(list);
  };

  useEffect(() => {
    getAllProfiles(fetchContext);
    if (validateTimeCondition(chargingPeriodList)) {
      if (validateTimeGaps(chargingPeriodList)) {
        setGapError("");
        // setGapErrorSubmit(true);
        if (!chargingPeriodList[chargingPeriodList.length - 1].end_time)
          setIsAddDisabled(true);
        else {
          setChargingSchedulePeriod(
            generateChargingSchedulePeriod(
              chargingPeriodList,
              values.start_date
            )
          );
          setIsAddDisabled(false);
        }
      } else {
        setIsAddDisabled(true);
        // setGapErrorSubmit(false);
        setGapError("* Time should not be overlapping");
      }
    } else {
      setIsAddDisabled(true);
      // setGapErrorSubmit(false);
      setGapError("* Start time must be before end time");
    }
  }, [chargingPeriodList]);

  useEffect(() => {
    setMaxLimit(Number(values?.max_limit));

    console.log(values?.max_limit, values);
    siteProfileList.forEach((item) => {
      if (!(item.limit && item.charger_id)) {
        setIsAddDisabled(true);
      } else setIsAddDisabled(false);
    });

    if (values.max_limit < 0) {
      setValues({ ...values, max_limit: 0 });
    }
  }, [siteProfileList, values]);

  useEffect(() => {
    setChargingSchedulePeriodGraphData(
      convertToOutputList(chargingSchedulePeriod)
    );
    setChargingSchedulePeriodLegendGraphData(
      convertToOutputLegendList(chargingSchedulePeriod)
    );
  }, [chargingSchedulePeriod]);

  useEffect(() => {
    if (values) {
      setPayload({
        connector_id: values.connector_id,
        charger_id: values.charger_id ? values.charger_id.id : null,
        start_date: values.start_date
          ? formatDateToISOWithMidnight(values.start_date)
          : null,
        charging_schedule_period: chargingSchedulePeriod,
      });
    }
  }, [values, chargingSchedulePeriod]);

  useEffect(() => {
    console.log("values", values);

    getconnector(values?.charger_id?.charger_id_new);
    const currentDate = new Date();
    const startDate = values?.start_date ? new Date(values?.start_date) : "";
    let startDateOnly;
    if (values?.start_date) {
      startDateOnly = new Date(
        startDate?.getFullYear(),
        startDate?.getMonth(),
        startDate?.getDate()
      );
    }
    // Function to format date to MM-dd-yyyy
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0"); // Get day and ensure two digits
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and ensure two digits
      const year = date.getFullYear(); // Get year
      return `${month}-${day}-${year}`; // Return in MM-dd-yyyy format
    };
    // Convert currentDate and startDateOnly to MM-dd-yyyy strings
    const currentDateOnly = formatDate(currentDate);
    const formattedStartDateOnly = startDateOnly
      ? formatDate(startDateOnly)
      : "";
    if (currentDateOnly > startDateOnly && values?.start_date !== undefined) {
      setstartdateCompareError(true);
    } else {
      setstartdateCompareError(false);
      if (new Date(values.start_date) > new Date(values.end_date)) {
        setDateCompareError(true);
      } else {
        setDateCompareError(false);
      }
      if (new Date(values.start_time) > new Date(values.end_time)) {
        setTimeCompareError(true);
      } else {
        setTimeCompareError(false);
      }
    }
  }, [values]);

  const changeSelectOptionHandler = (event) => {
    values.limit_type = event.target.value;
    setSelected(event.target.value);
    //  setDisabledChargerOutput(false);
  };

  const handleChargerOutputDisable = (event) => {
    changeSelectOptionHandler(event);
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>Multi Stack Load Management</div>
        </Toolbar>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (siteEvse === "1") handleSubmitLoadSite();
            else handleSubmit(false, payload);
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              &nbsp;
            </Grid>

            {/* SiteEVSERadioGroup */}
            <Grid item xs={12}>
              <SiteEVSERadioGroup
                name="userRole"
                required={true}
                value={siteEvse}
                onChange={handleSiteEVSE}
              />
            </Grid>

            {/* Form for SiteEVSE === "1" */}
            {siteEvse === "1" && (
              <>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <SitesSelect
                    value={values.site_id}
                    error={errors.site_id}
                    required={true}
                    onChange={handleInputChange}
                    style={{ width: "90%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.Input
                    name="max_limit"
                    label="Site Limit"
                    required={true}
                    type="number"
                    max={10000}
                    value={values.max_limit}
                    onChange={handleInputChange}
                    style={{ width: "90%" }}
                  />
                </Grid>

                <Grid item xs={isDesktop ? 4 : 12}>
                  <LimitTypeSelect
                    label="Limit Type"
                    name="limit_type"
                    value={selected}
                    error={errors.limit_type}
                    required
                    onChange={handleChargerOutputDisable}
                    //onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={isDesktop ? 3 : 8}>
                  <Controls.DatePicker
                    name="start_date"
                    label="From Date"
                    value={values.start_date ? values.start_date : null}
                    onChange={handleInputChange}
                    error={errors.start_date}
                    required={true}
                    //fullWidth
                    style={{ width: "98%" }}
                  />
                  {startdateCompareError && (
                    <Grid item>
                      <p style={{ color: "red" }}>
                        *The start date must be current and future date
                      </p>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={isDesktop ? 3 : 12}>
                  <Controls.TimePicker
                    name="start_time"
                    label="Start Time"
                    value={values.start_time ? values.start_time : null}
                    onChange={handleInputChange}
                    required={true}
                    //fullWidth
                    style={{ width: "98%" }}
                  />
                  <Grid item>
                    {timeCompareError ? (
                      <p style={{ color: "red" }}>
                        *The start time must be before end time
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={isDesktop ? 3 : 8}>
                  <Controls.DatePicker
                    name="end_date"
                    label="To Date"
                    value={values.end_date ? values.end_date : null}
                    onChange={handleInputChange}
                    error={errors.end_date}
                    required={true}
                    //fullWidth
                    style={{ width: "98%" }}
                  />
                  <Grid item>
                    {dateCompareError ? (
                      <p style={{ color: "red" }}>
                        *The start date must be before end date
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={isDesktop ? 3 : 12}>
                  <Controls.TimePicker
                    name="end_time"
                    label="End Time"
                    //minDate={minDate}
                    value={values.end_time ? values.end_time : null}
                    required={true}
                    onChange={handleInputChange}
                    style={{ width: "98%" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>
                    &nbsp;&nbsp;<b>Chargers</b>
                  </FormLabel>
                </Grid>

                {siteProfileList?.map((x, i) => (
                  <>
                    <Grid item xs={isDesktop ? 4 : 12}>
                      <Controls.Input
                        name="limit"
                        label="Limit"
                        required={true}
                        type="number"
                        max={maxLimit}
                        value={x.limit}
                        onChange={(e) => handlePeriodChangeSite(e, i)}
                        style={{ width: "90%" }}
                      />
                    </Grid>

                    <Grid item xs={isDesktop ? 4 : 12}>
                      <SiteChargingStationSelect
                        name="charger_id"
                        required={true}
                        value={x.charger_id}
                        site_id={values.site_id}
                        onChange={(e) => handlePeriodChangeSite(null, i, e.id,e.charger_output)}
                        //  style={{ width: "90%" }}
                      />
                    </Grid>

                    <Grid item xs={isDesktop ? 4 : 12}>
                      {siteProfileList.length !== 1 && (
                        <Controls.Button
                          text="X"
                          onClick={() =>
                            setSiteProfileList(
                              siteProfileList.filter((_, index) => i !== index)
                            )
                          }
                        />
                      )}
                    </Grid>
                  </>
                ))}

                <Grid item xs={12}>
                  <Controls.Button
                    text="Add"
                    disabled={isAddDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      setSiteProfileList([
                        ...siteProfileList,
                        {
                          limit: 0,
                          charger_id: {
                            id: null,
                            label: null,
                            charger_output: "",
                          },
                        },
                      ]);
                    }}
                  />
                </Grid>
              </>
            )}

            {/* Form for SiteEVSE !== "1" */}
            {siteEvse !== "1" && (
              <>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <ChargingStationSelect
                    value={values.charger_id}
                    error={errors.charger_id}
                    required={true}
                    onChange={(e) => setValues({ ...values, charger_id: e })}
                    style={{ width: "90%" }}
                  />
                </Grid>

                <Grid item xs={isDesktop ? 4 : 12}>
                  <Controls.DatePicker
                    name="start_date"
                    label="From Date"
                    value={values.start_date || null}
                    onChange={handleInputChange}
                    error={errors.start_date}
                    required={true}
                    style={{ width: "98%" }}
                  />
                </Grid>

                <Grid item xs={isDesktop ? 3 : 12}>
                  <Controls.Select
                    label="Connector ID"
                    options={options}
                    required={true}
                    name="connector_id"
                    value={
                      connector === 1 ? options[0]?.name : values.connector_id
                    }
                    onChange={handleInputChange}
                  ></Controls.Select>
                </Grid>

                {values.start_date && (
                  <>
                    <Grid item xs={12}>
                      <FormLabel>
                        &nbsp;&nbsp;<b>Define Charging Periods</b>
                      </FormLabel>
                    </Grid>

                    {chargingPeriodList?.map((x, i) => (
                      <>
                        <Grid item xs={3}>
                          <Controls.Input
                            name="limit"
                            label="Limit"
                            required={true}
                            type="number"
                            max={1000}
                            value={x.limit}
                            onChange={(e) => handlePeriodChange(e, i)}
                            style={{ width: "80%" }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controls.TimePicker
                            name="start_time"
                            label="From"
                            value={x.start_time || null}
                            onChange={(e) => handlePeriodChange(e, i)}
                            required={true}
                            style={{ width: "98%" }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controls.TimePicker
                            name="end_time"
                            label="To"
                            value={x.end_time || null}
                            onChange={(e) => handlePeriodChange(e, i)}
                            required={true}
                            style={{ width: "98%" }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          {chargingPeriodList.length !== 1 && (
                            <Controls.Button
                              text="X"
                              onClick={() =>
                                setChargingPeriodList(
                                  chargingPeriodList.filter(
                                    (_, index) => i !== index
                                  )
                                )
                              }
                            />
                          )}
                        </Grid>
                      </>
                    ))}

                    <Grid item xs={3}>
                      {/* {gapError ? (
                       <p style={{ color: "red" }}>{gapError}</p>
                     ) : (
                       ""
                     )} */}
                    </Grid>
                    <Grid item xs={3}>
                      {gapError ? (
                        <p style={{ color: "red" }}>{gapError}</p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Button
                        text="Add"
                        disabled={isAddDisabled}
                        onClick={(e) => {
                          e.preventDefault();
                          setChargingPeriodList([
                            ...chargingPeriodList,
                            {
                              limit: 0,
                              start_time:
                                chargingPeriodList[
                                  chargingPeriodList.length - 1
                                ].end_time,
                              end_time: null,
                            },
                          ]);
                        }}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item xs={12}>
              <div>
                <Controls.Button type="submit" text="Submit" />
                <Controls.Button
                  text="Clear"
                  color="default"
                  onClick={(e) => {
                    e.preventDefault();
                    setChargingPeriodList([
                      { limit: 0, start_time: null, end_time: null },
                    ]);
                    setSiteProfileList([
                      {
                        limit: "",
                        charger_id: null,
                      },
                    ]);
                    values.limit_type = null;
                    setValues({
                      connector_id: null,
                      charger_id: null,
                      start_date: null,
                      // limit_type:null,
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Form>

        <TblContainer>
          <TblHead />
          <TableBody>
            {loaded ? (
              profiles?.map((item) => (
                <TableRow>
                  <TableCell>{item.charging_profile_id}</TableCell>
                  <TableCell>{item.charger_id}</TableCell>
                  <TableCell>{item.charging_schedule_period}</TableCell>
                  {!fetchContext.isView && (
                    <TableCell className="text-nowrap">
                      <Confirm
                        id={item.charging_profile_id}
                        title={"Are you sure to clear this profile?"}
                        subTitle={"You can't undo this operation"}
                        onConfirmClick={() =>
                          handleSubmit(true, {
                            charger_id: item.charger_id,
                            charging_profile_id: item.charging_profile_id,
                            connector_id: item.connector_id || 1,
                          })
                        }
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <Loader />
            )}
          </TableBody>
        </TblContainer>
      </Paper>
    </>
  );
};

export default LoadSharing;
