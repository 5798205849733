import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@material-ui/core";
import { FetchContext } from "../../context/FetchContext";
import Controls from "../../components/controls/Controls";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
  },
}));

const DeviceTypeSelect = (props) => {
  const classes = useStyles();
  const { name, label, value = "", error = null, onChange, required = false, valuesData, setValues } = props;
  const deviceType = ["Nayax", "Magtek Swiper", "Payter", "Castle Cloud", "AMP Cloud"];

  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValuesData] = useState([]);

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.post("/get-charger-models", {
          manufacturer_id: valuesData?.manufacturer_id,
        });
        if (data?.status) {
          setValuesData(data?.data);
        } else {
          setValuesData([]);
          setValues({ ...valuesData, charger_model: "" });
        }
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };
    if (valuesData?.manufacturer_id) {
      getAllSites(fetchContext);
    }
  }, [fetchContext, valuesData?.manufacturer_id]);

  return loaded && values?.length > 0 ? (
    <FormControl
      variant="standard"
      required={values?.length ? true : false}
      className={classes.formControl}
      style={{ width: "95%" }}
    >
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <MuiSelect
        className={classes.select}
        variant="standard"
        label={label}
        name={name}
        value={value}
        required={values?.length ? true : false}
        error={error}
        //options={values}
        //fullWidth={fullWidth}
        onChange={onChange}
      >
        <MenuItem value={""}>None</MenuItem>
        {values?.map((item) => (
          <MenuItem value={item?.name}>{item?.name}</MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  ) : (
    <Controls.Input
      name="charger_model"
      label="Models"
      style={{ width: "95%" }}
      disabled
    >
      <CircularProgress size="1rem" />{" "}
    </Controls.Input>
  );
};

export default DeviceTypeSelect;
