import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      transition: "0.2s ease-in-out",
    },
  },
  labelFocused: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
}));

const Input = (props) => {
  const { name, label, value = "", error = null, onChange, helperText, ...other } = props;
  const classes = useStyles();

  return (
    <TextField
      variant="standard"
      label={label}
      name={name}
      value={value}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.labelFocused,
        },
      }}
      autoComplete="off"
      onChange={onChange}
      error={Boolean(error)} // Ensure error is a boolean
      helperText={ error ? helperText: "" }
      {...other}
    />
  );
};

export default Input;
