//import React, { lazy, Suspense, useContext } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
import React, { createContext, useContext, useState } from "react";
import {
  Redirect,
  //HashRouter,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./App.css";
// import { CssBaseline } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
//import Typography from "@mui/material/Typography";

import { AuthContext, AuthProvider } from "../context/AuthContext";
import { FetchContext, FetchProvider } from "../context/FetchContext";
import ChargerGroups from "../pages/ChargerGroups/ChargerGroups";
import ChargingStations from "../pages/ChargingStations/ChargingStations";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Signin/login";
import SiteGroups from "../pages/SiteGroups/SiteGroups";
import ShowAllSites from "../pages/SiteGroups/ShowAllSites";

import Sites from "../pages/Sites/Sites";
import Sections from "./Sections";

import ChargerGroupsDetails from "../pages/Reports/ChargerGroupsDetails";
import SessionByChargerGroups from "../pages/Reports/SessionByChargerGroups";
import SessionByChargerGroupsNext from "../pages/Reports/SessionByChargerGroupsNext";
import SessionBySites from "../pages/Reports/SessionBySites";
import SessionBySitesNext from "../pages/Reports/SessionBySitesNext";
import SitesDetails from "../pages/Reports/SitesDetails";
import Sessions from "../pages/Sessions/Sessions";

import UpTimeReport from "../pages/Reports/UpTimeReport";
import Users from "../pages/Users/Users";
//import Configurations from '../pages/Configurations/Configurations';
import RfidDetails from "../pages/RFID/RfidDetails";

import Prices from "../pages/PricePlans/Prices";
import TaxRates from "../pages/PricePlans/TaxRates";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import EnergySub from "../pages/Dashboard/EnergySub";
import RevenueSub from "../pages/Dashboard/RevenueSub";
import SessionGraph from "../pages/Dashboard/SessionGraph";
import FaultReportDetail from "../pages/FaultReport/FaultReportDetail";
import FirmReportUpload from "../pages/Firmware/FirmReportUpload";
import InvalidTagReport from "../pages/InvalidTagReport/InvalidTagReport";
import LoadSharing from "../pages/LoadBalancing/LoadSharing";
import LoadSharingSingleStack from "../pages/LoadBalancing/LoadSharingSingleStack";
import RevenueReport from "../pages/Reports/RevenueReport/RevenueReport";
import RevenueReportNext from "../pages/Reports/RevenueReport/RevenueReportNext";
import Sustainability from "../pages/Reports/Sustainability/Sustainability";
import SustainabilityDetails from "../pages/Reports/Sustainability/SustainabilityDetails";
import SustainabilityNext from "../pages/Reports/Sustainability/SustainabilityNext";
import ButtonGroupMM from "../pages/Manufacturing/ButtonGroupMM";
import ShowChargers from "../pages/ChargingStations/ShowChargers";
import { DesktopProvider } from "../context/DisplayContext";
import RevenueGraphMain from "../pages/Dashboard/Revenue/RevenueGraphMain";
import SessionBySitesMain from "../pages/Reports/SessionBySite/SessionBySitesMain";
import ForgetPasswordForm from "../pages/Signin/ForgetPasswordForm";
import DeletedChargingStation from "../pages/ChargingStations/DeletedChargingStation";
import DeclinedSessionReport from "../pages/Reports/DeclinedSessionReport";
import SessionsRfid from "../pages/SessionRfidReport/SessionsRfid";
import OccupancyFee from "../pages/OccupancyFee/OccupancyFee";
import TermAndCondition from "../pages/TermAndCondition/TermAndCondition";


//const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
// const Sites = lazy(() => import('../pages/Sites/Sites'));
// const SiteGroups = lazy(() => import('../pages/SiteGroups/SiteGroups'));
// const ChargingStations = lazy(() => import('../pages/ChargingStations/ChargingStations'));
// const ChargerGroups = lazy(() => import('../pages/ChargerGroups/ChargerGroups'));
// const Sessions = lazy(() => import('../pages/Sessions/Sessions'));
// const Users = lazy(() => import('../pages/Users/Users'));
// const Configurations = lazy(() => import('../pages/Configurations/Configurations'));
//createMuiTheme
const theme = createTheme({
  palette: {
    primary: {
      main: "#a31112",
      light: "#f8324526",
    },
    secondary: {
      main: "#a31112",
      light: "#8a0a0b",
    },
    background: {
      //default: "#8a0a0b"
    },
    string: {
      main: "#a31112",
      light: "#a31112",
    },
  },
  typography: {
    fontFamily: [
      "Aldrich",
      //"Roboto",
      // "Helvetica Neue",
      // "Arial",
      // "sans-serif"
    ].join(","),
  },
  overrides: {
    // MuiPagination: {
    //   outlined:{
    //     border: '1px solid #000',
    //   }
    // },
    // MuiCssBaseline: {
    //   '@global': {
    //     '@font-face': [Aldrich],
    //   },
    // },
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
    MuiDialogTitle: {
      root: {
        color: "#a31112",
        padding: "0 0 0 5px",
      },
    },
    MuiSvgIcon: {
      root: {
        fill: "#a31112",
      },
    },
    MuiTableSortLabel: {
      icon: {
        fill: "#fff",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
      },
    },
    // MuiContainer: {
    //   '& .makeStyles-container-2': {
    //     width: 'none',
    //   }
    // },
    MuiDialog: {
      paper: {
        borderWidth: 5,
        borderRadius: 5,
        boxShadow: "0 2px 10px 0 #a31112",
        //borderColor: "#8a0a0b",
        //borderStyle: "solid",
        backgroundColor: "#fff",
        //color: "#fff"
      },
    },
  },
  props: {
    // MuiSvgIcon: {
    //   root: {
    //     fill: '#a31112',
    //   }
    // },
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

// const LoadingFallback = () => (
//   <Sections>
//     <div className="p-4">Loading...</div>
//   </Sections>
// );

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route exact path="/">
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    </Route>
    
  </Switch>
);

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);

  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() ? (
          <Sections>{children}</Sections>
        ) : (
          <Redirect to="/" />
        )
      }
    ></Route>
  );
};

const AdminOnlyRoute = ({ technician, children, ...rest }) => {
  const auth = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);

  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() &&
        (fetchContext?.isAdmin || (technician && fetchContext.isTechnician)) ? (
          <Sections>{children}</Sections>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    ></Route>
  );
};

const ViewOnlyRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);

  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() && !fetchContext?.isView ? (
          <Sections>{children}</Sections>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    ></Route>
  );
};

export const ChStStatusContext = createContext();

const App = () => {
  const fetchContext = useContext(FetchContext);

  const [flState, setFlState] = useState("fsn");
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DesktopProvider>
        <AuthProvider>
          <FetchProvider>
            <ChStStatusContext.Provider value={{ flState, setFlState }}>
              {/* <Router>            
                <AppRoutes />   
              </Router>       */}
              {/* <Router basename="/rede"> */}
              <Router>
                <Switch>
                  {/* <Route exact path="/" component={Login} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route component={() => <Redirect to="/" />} /> 
                  
                  */}
                  <Route path="/forget-password">
                    <ForgetPasswordForm />
                  </Route>
                  <AuthenticatedRoute path="/delete-charging-station">
                    {" "}
                    {/* <RevenueGraphMain />{" "} */}
                    <DeletedChargingStation />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/revenue-graph">
                    {" "}
                    <RevenueGraphMain />{" "}
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/energy-graph">
                    {" "}
                    <EnergySub />{" "}
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sessions-graph">
                    {" "}
                    <SessionGraph />{" "}
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/dashboard">
                    <Dashboard />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/site-accounts">
                    <SiteGroups />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sites/:siteName">
                    <Sites />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/sites">
                    <Sites />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/charging-stations">
                    <ChargingStations />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/charger-groups">
                    <ChargerGroups />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/charging-stations/:id">
                    <ChargingStations />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sessions">
                    <Sessions />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sustainability">
                    <Sustainability />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/reports-charger-groups">
                    <SessionByChargerGroups />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/reports-charger-groups-next/:id/:bymonth">
                    <SessionByChargerGroupsNext />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/charger-groups-details/:id/:csid/:bymonth">
                    <ChargerGroupsDetails />
                  </AuthenticatedRoute>
                  <AdminOnlyRoute technician={true} path="/fault-report">
                    <FaultReportDetail />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute technician={true} path="/invalid-tag-report">
                    <InvalidTagReport />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/declined-session-report">
                    <DeclinedSessionReport />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/rfid-sessions-report">
                    <SessionsRfid />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/term-and-condition">
                    <TermAndCondition />
                  </AdminOnlyRoute>
                  {/* <AdminOnlyRoute path="/firmware-detail" >
                    <FirmwareDetail /> 
                  </AdminOnlyRoute> */}
                  <AuthenticatedRoute path="/reports-sites">
                    <SessionBySitesMain />
                    {/* <SessionBySites /> */}
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/reports-sites-next/:id/:bymonth">
                    <SessionBySitesNext />
                  </AuthenticatedRoute>
                  <AdminOnlyRoute path="/manufacturer">
                    <ButtonGroupMM />
                  </AdminOnlyRoute>
                  <AuthenticatedRoute path="/sites-details/:id/:csid/:bymonth">
                    <SitesDetails />
                  </AuthenticatedRoute>
                  <AdminOnlyRoute path="/revenue-report">
                    <RevenueReport />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/revenue-report-next/:id/:bymonth">
                    <RevenueReportNext />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/firmware-upload">
                    <FirmReportUpload />
                  </AdminOnlyRoute>
                  <AuthenticatedRoute path="/load-sharing">
                    <LoadSharing />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/load-sharing-single">
                    <LoadSharingSingleStack />
                  </AuthenticatedRoute>

                  {/* <AuthenticatedRoute path="/change-configuration" >
                      <ChangeConfig />
                    </AuthenticatedRoute>               */}

                  {/* <AuthenticatedRoute path="/reports-displacements" >
                    <SessionByDisplacements /> 
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/reports-displacements-next/:id/:bymonth" >
                    <SessionByDisplacementsNext /> 
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/displacements-details/:id/:csid/:bymonth" >
                    <DisplacementDetails /> 
                  </AuthenticatedRoute> */}
                  {/* <AuthenticatedRoute path="/reports-greenhousegas" >
                    <SessionByGreenhouseGas /> 
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/reports-greenhousegas-next/:id/:bymonth" >
                    <SessionByGreenhouseGasNext /> 
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/greenhousegas-details/:id/:csid/:bymonth" >
                    <GreenhouseGasDetails /> 
                  </AuthenticatedRoute> */}
                  <AuthenticatedRoute path="/sustainability">
                    <Sustainability />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sustainability-next/:id/:bymonth">
                    <SustainabilityNext />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/sustainability-details/:id/:csid/:bymonth">
                    <SustainabilityDetails />
                  </AuthenticatedRoute>
                  <AdminOnlyRoute path="/report-uptime">
                    <UpTimeReport />
                  </AdminOnlyRoute>
                  <AdminOnlyRoute path="/users">
                    <Users />
                  </AdminOnlyRoute>
                  {/* <AuthenticatedRoute path="/configurations" >
                    <Configurations /> 
                  </AuthenticatedRoute> */}
                  <ViewOnlyRoute path="/rfid">
                    <RfidDetails />
                  </ViewOnlyRoute>
                  <AuthenticatedRoute path="/prices">
                    <Prices />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/occupancyfee">
                    <OccupancyFee />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/tax-rates">
                    <TaxRates />
                  </AuthenticatedRoute>
                  <UnauthenticatedRoutes />
                </Switch>
              </Router>
            </ChStStatusContext.Provider>
          </FetchProvider>
        </AuthProvider>
      </DesktopProvider>
    </ThemeProvider>
  );
};

export default App;
