import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";

const SiteChargingStationSelect = (props) => {
  const { value = "", error = null, onChange, site_id } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);
  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.post(
          "/select-site-charging-stations",
          { site_id }
        );
        setValues(
          data.data.map((item, index) => {
            return {
              id: item.ocpp_cbid,
              label: `${item.name} (${item?.ocpp_cbid})`,
              charger_id_new: item?.id,
              charger_output: item?.charger_output,
            };
          })
        );
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllSites(fetchContext);
  }, [fetchContext, site_id]);

  return (
    <>
      {loaded ? (
        <Controls.SearchWithSelect
          name="charger_id"
          label="Charging Stations"
          value={value}
          required={true}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "113%" }}
        />
      ) : (
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input
          name="charger_id"
          label="Charging Stations"
          style={{ width: "113%" }}
        >
          <CircularProgress size="1rem" />{" "}
        </Controls.Input>
      )}
    </>
  );
};

export default SiteChargingStationSelect;
