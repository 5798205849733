import React, { useContext, useState } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { ImportExport } from "@material-ui/icons";
import { CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { FetchContext } from "../../../context/FetchContext";
import { TYPE_1, TYPE_2, TYPE_3 } from "../RevenueReport/RevenueType";

const ExportSiteWithBreakdown = ({ filterDate, byMonth, selectedSiteId, revenueData }) => {
  const fetchContext = useContext(FetchContext);
  const [data, setData] = useState(revenueData);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display
  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  function formatNumberWithLeadingZero(number) {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(2);
    else response = value1 / value2;

    return response;
  };

  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    const dataArr = [];
    // const finalPayload = {
    //   ...filterDate,
    //   export: true,
    //   date: byMonth,
    //   site_id: selectedSiteId,
    // };
    // await fetchContext.authAxios.post(`/get-revenue-by-site`, finalPayload).then(({ data }) => {
    //   const pdata = data.data.data;

    //   if (pdata) {
    //     for (let item of pdata) {
    //       //dataObj.sessionID = item.id;
    //       let obj = {
    //         site: item.site_name,
    //         amount: formatNumber(item.amount),
    //         energy: item?.energy ? Number(item?.energy).toFixed(4) : "0.0000",
    //         duration: item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-",
    //         total: item.total,
    //         charging_station_name: item.charging_station_name,
    //         address: item.address,
    //         city: item.city,
    //         state: item.state,
    //         postal_code: item.postal_code,
    //         latitude: item.latitude,
    //         longitude: item.longitude,
    //         parking_amount: item?.parking_amount ? formatNumber(item?.parking_amount) : 0,
    //         tax_amount: item?.tax_amount ? formatNumber(item?.tax_amount) : 0,
    //         net_amount: formatNumber(item?.amount - item?.parking_amount - item?.tax_amount),
    //       };
    //       dataArr.push(obj);
    //     }
    //   }
    // });

    dataArr.push(
      {
        index: "",
        site_name: selectedSiteId?.name,
        // amount: selectedSiteId?.amount,
        site_payout: selectedSiteId?.site_payout.toFixed(2),
        processing_fee: selectedSiteId?.processing_fee,
        licensing_fee: selectedSiteId?.licensing_fee,
        energy: selectedSiteId?.energy,
        duration: selectedSiteId?.charging_duration,
        total: selectedSiteId?.number_of_session,
      },
      {
        index: "",
        site_name: "",
        site_payout: "",
        processing_fee: "",
        licensing_fee: "",
        energy: "",
        duration: "",
        total: "",
      },
      {
        index: "",
        site_name: "",
        site_payout: "",
        processing_fee: "Date",
        licensing_fee: "Energy Delivered (KWH)",
        energy: "Site Payout",
        duration: "",
        total: "",
      }
    );
    // if (Boolean(data[selectedSiteId?.id]?.length)) {

    //   for (let index = 0; index < data[selectedSiteId?.id]?.length; index++) {
    //     let item = data[selectedSiteId?.id][index];
    //     let obj = {
    //       index: index + 1,
    //       site_name: "",
    //       // site_payout: `$${Number(item.site_payout).toFixed(2) || 0}`,
    //       processing_fee: `${formatNumberWithLeadingZero(item?.date?.c?.month)}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${item?.date?.c?.year}`,
    //       licensing_fee: Number(item.usage).toFixed(2),
    //       // duration: `$${Number(item.amount).toFixed(2) || 0}`,
    //       energy: `$${Number(item.site_payout).toFixed(2) || 0}`,
    //       total: "",
    //     };
    //     dataArr.push(obj);
    //   }

    // } else {
    let final_dta = await getSiteWiseData(
      fetchContext,
      byMonth,
      filterDate,
      selectedSiteId?.id
    );
    for (let index = 0; index < final_dta?.length; index++) {
      let item = final_dta[index];
      let obj = {
        index: index + 1,
        site_name: "",
        // site_payout: `$${Number(item.site_payout).toFixed(2) || 0}`,
        processing_fee: `${formatNumberWithLeadingZero(
          item?.date?.c?.month
        )}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${
          item?.date?.c?.year
        }`,
        licensing_fee: Number(item.usage).toFixed(4),
        // duration: `$${Number(item.amount).toFixed(2) || 0}`,
        energy: `$${item.site_payout}`,
        total: "",
      };
      dataArr.push(obj);
    }
    setLoading(false); // Set loading to false after fetching data
    setShowMessage(false); // Show message when starting download

    // }
    return Promise.resolve(dataArr);
  };

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if (item?.agreement_type) {
      switch (item?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item?.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const getSiteWiseData = async (
    fetchContext,
    byMonth,
    filterDate,
    site_id
  ) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        `/get-revenue-by-site`,
        {
          date: byMonth,
          export: true,
          from_date: filterDate?.from_date,
          to_date: filterDate?.to_date,
          range: filterDate?.range,
          site_id: site_id,
        }
      );
      //let res = Object.values(data.data);
      // setSiteDetail(data.data.data);
      return await handleFilterData(data.data, byMonth, filterDate, site_id);

      // if (data.data.total && data.data.total > 20) {
      //   let count = Math.ceil(data.data.total / 20);
      //   setCounts(count);
      // } else {
      //   setCounts(1);
      // }

      // setIsSiteDetailLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const handleFilterData = (
    site_data,
    date,
    { range, to_date, from_date },
    site_id
  ) => {
    const dailyData = {};
    let start;
    let end;
    let transactions = site_data;
    if (!transactions) {
      return;
    }

    if (range) {
      let given_to_Date = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      let to_js_date = given_to_Date.toJSDate();

      let given_from_Date = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      let from_js_date = given_from_Date.toJSDate();

      start = DateTime.fromJSDate(from_js_date);
      end = DateTime.fromJSDate(to_js_date);
    } else {
      let givenDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      let startDate = givenDate.toJSDate();
      let endDate = givenDate.endOf("month").toJSDate();
      start = DateTime.fromJSDate(startDate);
      end = DateTime.fromJSDate(endDate);
    }

    // const dailyData = {
    //   datetime: DateTime,
    //   count: 0,
    //   usage: 0
    // };

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        amount: 0,
        usage: 0,
        site_payout: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      const key = DateTime.fromSQL(transaction.created_at)
        .startOf("day")
        .toISO();
      if (transaction.meter_diff / 1000 > 0) {
        if (key && dailyData[key]) {
          dailyData[key].usage += transaction?.meter_diff / 1000;
        }
      }
      if (key && dailyData[key]) {
        dailyData[key].site_payout += transaction?.processingFee.site_payout;
      }
    });

    let updated_data = (data[site_id] = Object.values(dailyData).reduce(
      (acc, { count, datetime, amount, usage, site_payout }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;
        return [
          ...acc,
          datetime !== undefined && {
            amount: amount.toFixed(2),
            average: count ? getRound(amount, count) : "0.00",
            month,
            date: datetime,
            usage: usage.toFixed(2),
            energy_average: count ? getRound(usage, count) : 0,
            site_payout: site_payout.toFixed(2),
          },
        ];
      },
      []
    ));

    return updated_data;
  };

  const columns = [
    { id: "index", displayName: "Index" },
    { id: "site_name", displayName: "Site Name" },
    { id: "site_payout", displayName: "Site Payout (USD)" },
    { id: "processing_fee", displayName: "Processing Fee (USD)" },
    { id: "licensing_fee", displayName: "Licensing Fee (USD)" },
    { id: "energy", displayName: "Energy Delivered (KWH)" },
    { id: "duration", displayName: "Average Charging Duration (Minutes)" },
    { id: "total", displayName: "Number of Sessions" },
    // { id: "charging_station_name", displayName: "Charging Station" },
    // { id: "address", displayName: "Address" },
    // { id: "city", displayName: "City" },
    // { id: "state", displayName: "State" },
    // { id: "postal_code", displayName: "Zipcode" },
    // { id: "latitude", displayName: "Latitude" },
    // { id: "longitude", displayName: "Longitude" },
  ];

  const revenueFileName = () => {
    if (filterDate?.range) {
      const from_date = filterDate?.from_date ? filterDate?.from_date : "";
      const from_date_parsedDate = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      const from_date_formattedDate =
        from_date_parsedDate.toFormat("d LLL yyyy");

      const to_date = filterDate?.to_date ? filterDate?.to_date : "";
      const to_date_parsedDate = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      const to_date_formattedDate = to_date_parsedDate.toFormat("d LLL yyyy");
      // return `Revenue Report (${from_date_formattedDate} to ${to_date_formattedDate})`
      if (
        from_date_formattedDate !== "Invalid DateTime" &&
        to_date_formattedDate !== "Invalid DateTime"
      ) {
        return `${selectedSiteId?.name} Revnue Breakdown (${from_date_formattedDate} to ${to_date_formattedDate})`;
      } else {
        return `${selectedSiteId?.name} Revnue Breakdown`;
      }
    } else {
      const date = byMonth ? byMonth : "";
      const date_parsedDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      const date_formattedDate = date_parsedDate.toFormat("LLL yyyy");
      // return `Revenue Report (${date_formattedDate})`;
      if (date_formattedDate !== "Invalid DateTime") {
        return `${selectedSiteId?.name} Revnue Breakdown (${date_formattedDate})`;
      } else {
        return `${selectedSiteId?.name} Revnue Breakdown`;
      }
    }
  };

  return (
    <>
      <CsvDownloader
        // filename={"Site Revenue Breakdown"}
        filename={revenueFileName()}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default ExportSiteWithBreakdown;