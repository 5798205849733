import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ReactECharts from "echarts-for-react";
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { FetchContext } from "../../context/FetchContext";
import useTable from "../../components/useTable";
import Loader from "../../components/Loader";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  responsiveChartsChargerGroup: {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    backgroundColor: "#fff",
    [theme.breakpoints.between("sm", "md")]: {
      height: "800px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 800,
    },
  },
}));

const SessionByChargerGroupsGraph = (props) => {
  const { byMonth, filterObject, setCallSecondary, callSecondary } = props;
  const fetchContext = useContext(FetchContext);
  const [dataMain, setDataMain] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();
  const { isDesktop } = useIsDesktop();
const getAll = async (fetchContext, byMonth) => {
  try {
    const { data: firstResponse } = await fetchContext.authAxios.post(
      `/get-report-by-charger-group-all`,
      {
        date: byMonth,
        export: false,
        ...filterObject,
      }
    );

    if (!firstResponse.status || !firstResponse.data?.length) {
      setDataMain([]);
      setLoaded(true);
      setCallSecondary(false);
      return;
    }

    const totalPages = firstResponse.totalPages;
    let allData = [...firstResponse.data];

    if (totalPages > 1) {
      const pageRequests = [];
      for (let page = 2; page <= totalPages; page++) {
        pageRequests.push(
          fetchContext.authAxios.post(
            `/get-report-by-charger-group-all?page=${page}`,
            {
              date: byMonth,
              export: false,
              ...filterObject,
              page,
            }
          )
        );
      }

      const responses = await Promise.all(pageRequests);
      responses.forEach(({ data }) => {
        if (data.status && data.data?.length) {
          allData.push(...data.data);
        }
      });
    }

    setDataMain(allData.reverse());
    setLoaded(true);
    setCallSecondary(false);
  } catch (err) {
    setCallSecondary(false);
    console.log("the err", err);
  }
};


  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "amount", label: "Amount (USD)" },
    { id: "energy", label: "Energy (KWH)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const { TblContainer } = useTable(headCells);

  useEffect(() => {
    // if (callSecondary) {
      getAll(fetchContext, byMonth, filterObject);
    // }
  }, [fetchContext, byMonth, filterObject, callSecondary]);

  // const grid = {
  //   left: 100,
  //   right: 100,
  //   top: 50,
  //   bottom: 200,
  // };

  //Bar_2
  // const getOption = () => {
  //   const commonTextStyle = {
  //     color: "#000",
  //     fontFamily: "Aldrich",
  //   };
  //   const labelTextStyle = {
  //     fontFamily: "Aldrich",
  //   };

  //   return {
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         type: "shadow",
  //       },
  //       textStyle: commonTextStyle,
  //     },
  //     legend: {
  //       selectedMode: true,
  //     },
  //     grid,
  //     yAxis: {
  //       type: "value",
  //     },
  //     xAxis: {
  //       type: "category",
  //       axisLabel: {
  //         rotate: -45,
  //       },
  //       data:
  //         dataMain?.length > 0
  //           ? dataMain?.map((c) => {
  //               return c.charger_group_name;
  //             })
  //           : [],
  //     },
  //     series: updatedGraphData,
  //   };
  // };

  //Bar_1
  const getOption = () => {
    const commonTextStyle = {
      color: "#000",
      fontFamily: "Aldrich",
    };
    const labelTextStyle = {
      fontFamily: "Aldrich",
    };

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        textStyle: commonTextStyle,
        formatter: (params) => {
          let tooltipText = `${params[0].axisValue}<br/>`;
          params.forEach((param) => {
            let value =
              param.seriesName === "Energy (KWH)"
                ? Number(param.value).toFixed(4)
                : param.value;
            tooltipText += `${param.marker} ${param.seriesName}: ${value}<br/>`;
          });
          return tooltipText;
        },
      },
      legend: {
        textStyle: commonTextStyle,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top: "25%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          textStyle: commonTextStyle,
        },
        axisLabel: {
          rotate: 0,
        },
      },
      yAxis: {
        type: "category",
        data: dataMain ? dataMain.map((item) => item.charger_group_name) : [],
        axisLabel: {
          textStyle: { ...commonTextStyle },
        },
      },
      series: [
        {
          name: "Amount (USD)",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: "#5470C6",
            // color: '#00CC00',
          },
          data: dataMain ? dataMain.map((item) => item.amount.toFixed(2)) : [],
        },
        {
          name: "Energy (KWH)",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          itemStyle: {
            color: "#91CC75",
          },
          emphasis: {
            focus: "series",
          },
          data: dataMain
            ? dataMain.map((item) => Number(item?.energy).toFixed(4))
            : [],
        },
        {
          name: "Average Charging Duration (Minutes)",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: "#FAC858",
            // color: '#00CC00',
          },
          data: dataMain
            ? dataMain.map((item) =>
                item?.timezone_duration
                  ? (item.timezone_duration / 60 / item.total).toFixed(2)
                  : 0
              )
            : [],
        },
        {
          name: "Number of Sessions",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            textStyle: labelTextStyle,
            formatter: "",
          },
          itemStyle: {
            color: "#EE6766",
          },
          emphasis: {
            focus: "series",
          },
          data: dataMain
            ? dataMain.map((item) => Number(item.total).toFixed(2))
            : [],
        },
      ],
    };
  };

  return (
    <>
      <TblContainer>
        {loaded ? (
          <div class="row my-3">
            <div class="col-md">
              <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
                <div class="card-body">
                  {/* <div className="card-header" style={{ background: "#fff" }}>
                          <div class="row">
                            <div class="col-md-6">
                              <strong class="card-title">SESSIONS</strong>{" "}
                            </div>
                            <div class="col-md-6 fw-bold text-end">{graphType} </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 totalkwhleft text-dark">Finished: {totalFinished}</div>
                            <div class="col-md-6 totalkwhright text-dark">Failed: {totalFailed}</div>
                          </div>
                        </div>
                        <div class="clearfix"></div> */}

                  <div className={classes.responsiveChartsChargerGroup}>
                    <ResponsiveContainer>
                      <ReactECharts
                        option={getOption()}
                        style={
                          isDesktop
                            ? {
                                height: "100%",
                                width: "100%",
                                paddingBottom: 100,
                              }
                            : {}
                        }
                      />
                    </ResponsiveContainer>
                    {/* <div className="w-100 px-3 d-flex flex-row justify-content-center pb-4">
                                  <span>
                                    {" "}
                                    <SquareIcon sx={{ color: "#FF0000" }} /> Failed
                                  </span>
                                  <span>
                                    {" "}
                                    <SquareIcon sx={{ color: "#00CC00" }} /> Finished
                                  </span>
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </TblContainer>
    </>
  );
};

export default SessionByChargerGroupsGraph;
