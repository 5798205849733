import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";

const ChargerStageSelect = (props) => {
  const {
    recordForEdit,
    value,
    error = null,
    onChange,
    filter,
    isrequired,
  } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  const [chargerStage, setchargerStage] = useState(
    filter ? "" : value || "INVENTORY"
  );

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.post(
          "/get-select-inputs",
          { type: "charger_stage" }
        );
        if (data.data && data.data.length > 0) {
          let temp = [ ...data.data];
          let temp2 = [{id:'None',name:'None'},...data.data];
          setValues(filter ? temp2 : temp);
          setLoaded(true);
        }
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllSites(fetchContext);
  }, [fetchContext]);

  useEffect(() => {
    if (value) {
      setchargerStage(value);
    }
  }, [value]);

  return (
    <>
      {loaded ? (
        <Controls.Select
          name="charger_stage"
          label="Charger Stage"
          value={chargerStage}
          // value={value}
          required={isrequired}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "95%" }}
        />
      ) : (
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input
          name="charger_stage"
          label="Charger Stage"
          style={{ width: "97.5%" }}
        >
          <CircularProgress size="1rem" />{" "}
        </Controls.Input>
      )}
    </>
  );
};

export default ChargerStageSelect;
