import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
// import RfidForm from "./RfidForm";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, Typography, Tooltip } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import Loader from "../../components/Loader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { DateTime } from "luxon";
import TimeZone from "../../components/controls/Timezone";

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));

const FaultReportDetail = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showSearch, setShowSearch] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    from_date: null,
    to_date: null,
    range: false,
    charger_id: null,
  });
  const headCells = [
    { id: "charger_id", label: "Charger Id" },
    { id: "connector_id", label: "Connector Id" },
    { id: "errorCode", label: "Error Code" },
    { id: "info", label: "Info" },
    { id: "status", label: "Status" },
    { id: "Vendor_Error_Code", label: "Vendor Error Code" },
    { id: "Vendor_Error_detail", label: "Error Detail" },
    { id: "Vendor_Id", label: "Vendor Id" },
    { id: "timestamp", label: "Timestamp" },
    // { id: "createdAt", label: "Created Date" },
    // { id: "charging_station", label: "Charging Station" },
    // { id: "site", label: "Site" },
    // { id: "connector", label: "Connector" },
    // { id: "rfid", label: "Authentication Type" },
    // { id: "site", label: "Site" },
    //{ id: 'actions', label: '', disableSorting: true }
  ];

  // if (!fetchContext.isView) {
  //   // headCells.push({ id: "actions", label: "", disableSorting: true });
  // }

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, filterObject) => {
    try {
      // const { data } = await fetchContext.authAxios.post(`/get-faulted-history?page=${page}`, filterObject);

      const { data } = await fetchContext.authAxios.post(`/get-faulted-history?page=${page}`, filterObject);
      setRecords(data.data);
      setTotalRecord(data.totalItems);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page, filterObject);
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const handleFilter = (fetchContext, page, values) => {
    getAll(fetchContext, page, values);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    let updatedValues = {
      ...values,
      from_date: values.from_date ? DateTime.fromJSDate(values.from_date).toFormat("dd-MM-yyyy") : null,
      to_date: values.to_date ? DateTime.fromJSDate(values.to_date).toFormat("dd-MM-yyyy") : null,
      range: !showSearch ? false : true,
    };
    handleFilter(fetchContext, page, updatedValues);
    setFilterObject(updatedValues);
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    handleFilter(fetchContext, 1, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      // range: false,
      charger_id: null,
      idtag: null,
    });
    setFilterObject({
      search_keyword: null,
      // range: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onShowHideFilter = () => {
    setShowFilter(!showFilter);
    setShowSearch(true);
    if (!showFilter || !showSearch) {
      // setLoaded(false);
      setFilterObject({
        dname: null,
        authentication_tag: null,
        authentication_type: null,
        site: null,
        charging_station: null,
      });
      handleFilter(fetchContext, 1, {
        search_keyword: null,
        from_date: null,
        to_date: null,
        // range: false,
        charger_id: null,
        idtag: null,
      });
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.searchIcon} onClick={onShowHideSearch}>
            {showSearch ? (
              <Tooltip title="Search">
                <SearchIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Search">
                <SearchOffIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {showFilter ? (
              <Tooltip title="Filter">
                <FilterAltIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltOffIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Fault Report
            </Typography>
          </div>
          {!fetchContext.isView && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setShowFilter(true);
              }}
            />
          )}
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>Fault Report</div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
              <div className={classes.filterIcon} onClick={onShowHideFilter}>
                {showFilter ? (
                  <Tooltip title="Filter">
                    <FilterAltIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove Filter">
                    <FilterAltOffIcon />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        {!showSearch && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearch} setFilterObject={setFilterObject} />}
        {!showFilter && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideFilter} setFilterObject={setFilterObject} />}

        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => {
                let string = item?.sites?.length > 0 && item?.sites?.map((site) => site?.name).join(", ");
                return (
                  <TableRow key={item.id}>
                    {/* <TableCell>{item.charging_stations ? item.charging_stations.name : "--"}</TableCell>
                    <TableCell>{item.charging_stations?.site ? item.charging_stations?.site?.name : "--"}</TableCell>
                    <TableCell>
                      {item?.charging_stations?.connectors?.length > 0
                        ? item?.charging_stations?.connectors?.map((data) => {
                          return data?.type;
                        }).join(', ')
                        : ""}
                    </TableCell> */}
                    <TableCell>
                      {item?.charger_id ? item?.charger_id : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.connector_id ? item?.connector_id : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.errorCode ? item?.errorCode : "-"}
                    </TableCell>
                    <TableCell>{item?.info ? item?.info : "-"}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      {item?.Vendor_Error_Code ? item?.Vendor_Error_Code : "-"}
                    </TableCell>
                    <TableCell size="medium">
                      {item?.description ? item?.description : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.Vendor_Id ? item?.Vendor_Id : "-"}
                    </TableCell>
                    {/* <TableCell>{item?.timestamp ? item?.timestamp : "-"}</TableCell> */}
                    <TableCell>
                      {item.timestamp ||
                      item.timestamp !== "0000-00-00 00:00:00" ? (
                        <TimeZone
                          dateTime={
                            item.timestamp ? item.timestamp : item.created_at
                          }
                          timezone={item?.charging_stations?.site?.timezone}
                        />
                      ) : (
                        <TimeZone
                          dateTime={item.created_at}
                          timezone={item?.charging_stations?.site?.timezone}
                        />
                      )}
                    </TableCell>{" "}
                    {/* <TableCell>{item?.created_at ? item?.created_at : "-"}</TableCell> */}
                    {/* <TableCell>{item.type}</TableCell>
                    <TableCell>
                      {item.sites?.length > 0 ? (string?.length > 40 ? string.substring(0, 40) + "..." : string) : item.sites ? item.sites.name : "--"}
                    </TableCell> */}
                    {/* {!fetchContext.isView && (
                      <TableCell class="text-nowrap">
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to delete this record?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                onDelete(item.id);
                              },
                            });
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </Controls.ActionButton>
                      </TableCell>
                    )} */}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Authenticate" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {/* <RfidForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} /> */}
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default FaultReportDetail;
