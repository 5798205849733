import React, { useContext, useState } from "react";
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { CircularProgress, Snackbar, Tooltip } from "@mui/material";

const ExportDataByChargerUsagesReport = ({ filterDate, byMonth }) => {
  const fetchContext = useContext(FetchContext);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const asyncFnComputeData = async () => {
    setLoading(true);
    setShowMessage(true);
    const dataArr = [];
    const finalPayload = {
      ...filterDate,
      export: true,
      date: byMonth,
    };

    await fetchContext.authAxios
      .post(`/getReportBySite-custom`, finalPayload)
      .then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
          for (let item of pdata) {
            let obj = {
              site_name: item?.site_name,
              charged_amount: formatNumber(item?.amount),
              energy: item?.energy ? Number(item?.energy).toFixed(4) : "0.0000",
              processing_fee: formatNumber(
                item?.processingFee?.processing_fee || 0
              ),
              licensing_fee: formatNumber(
                item?.processingFee?.licensing_fee || 0
              ),
              site_payout: formatNumber(item?.processingFee?.site_payout || 0),
              total_sessions: item?.total,
              avg_charging_duration: item?.timezone_duration
                ? (Number(item?.timezone_duration) / 60 / item?.total).toFixed(2)
                : "-",
            };
            dataArr.push(obj);
          }
        }
      });

    setLoading(false);
    setShowMessage(false);
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "site_name", displayName: "Site Name" },
    { id: "charged_amount", displayName: "Charged Amount (USD)" },
    { id: "energy", displayName: "Energy (KWH)" },
    { id: "processing_fee", displayName: "Processing Fee (USD)" },
    { id: "licensing_fee", displayName: "Licensing Fee (USD)" },
    { id: "site_payout", displayName: "Site Payout (USD)" },
    { id: "total_sessions", displayName: "Number of Sessions" },
    { id: "avg_charging_duration",displayName: "Average Charging Duration (Minutes)" },
  ];

  return (
    <>
      <CsvDownloader
        filename={"ChargerUsagesReport"}
        extension=".csv"
        separator=","
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <ImportExport style={{ fill: "#fff" }} />
          )}
        </Tooltip>
      </CsvDownloader>

      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="Downloading data..."
      />
    </>
  );
};

export default ExportDataByChargerUsagesReport;
