import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../../context/FetchContext";
import useTable from "../../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
// import Popup from "../../components/Popup";
// import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/Loader";
import ExportDataBySitesNext from "./../ExportDataBySitesNext";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { Table, TableBody, TableCell, Tooltip, Toolbar, Typography, TableHead, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
//import { ExpandTable } from '../../components/controls/ExpandTable';
import { Link, makeStyles } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import MonthRangeSelect from "./../MonthRangeSelect";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import { InfoOutlined } from "@material-ui/icons";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SessionBySitesFilter from "./../SessionBySitesFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useParams } from "react-router-dom";
import SessionBySitesGraph from "./../SessionBySitesGraph";
import SessionByChargerGroupsGraph from "./../SessionByChargerGroupsGraph";
import SiteEnergyUsage from "./../SessionBySite/SiteEnergyUsage";
import SiteRevenueUsage from "./../SessionBySite/SiteRevenueUsage";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import TimeZone from "../../../components/controls/Timezone";
import DescriptionIcon from "@mui/icons-material/Description";
import { el } from "date-fns/locale";
import ExportSiteRevenueBreakdown from "./../SessionBySite/ExportSiteRevenueBreakdown";
import ExportSiteWithBreakdown from "./../SessionBySite/ExportSiteWithBreakdown";
import { TYPE_1, TYPE_2, TYPE_3 } from "./../RevenueReport/RevenueType";
import ExportDataBySites from "../ExportDataBySites";
import ExportDataByChargerUsagesReport from "../ExportDataByChargerUsagesReport";

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    [theme.breakpoints.between("sm", "md")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
  table: {
    marginTop: theme.spacing(1),
    "& thead th": {
      fontWeight: "500",
      //color: theme.palette.string.main,
      color: "#fff",
      backgroundColor: theme.palette.secondary.light,
    },
    "& tbody td": {
      fontWeight: "400",
    },
    "& tbody tr": {
      borderBottom: "solid 1px #E0E0E0",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
}));

const ChargerUsageReport = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [isSiteDetailLoaded, setIsSiteDetailLoaded] = useState(false);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  const [page, setPage] = useState(1);
  const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  const [siteDetail, setSiteDetail] = useState([]);
  const [counts, setCounts] = useState(1);
  const [noteId, setNoteId] = useState(0);
  const [openNotes, setOpenNotes] = useState(false);
  const [sitePayoutCal, setSitePayoutCal] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState([]);
  const [showSearch, setShowSearch] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [energyRevenue, setEnergyRevenue] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState({ id: null, name: "" });
  const [totalRecord, setTotalRecord] = useState(0);
  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    from_date: null,
    to_date: null,
    range: false,
    export: false,
  });

  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "amount", label: "Amount (USD)" },
    { id: "energy", label: "Energy (KWH)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
  } = useTable(headCells);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const getAll = async (fetchContext, page, byMonth, filterObject) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        `/getReportBySite-custom?page=${page}`,
        {
          export: false,
          ...filterObject,
          date: byMonth,
        }
      );

      let arrayData = data.data;
      setTotalRecord(data.totalItems);
      const finalData = [];
      for (let i = 0; i < data.data.length; i++) {
       let outside = arrayData[i];
        finalData.push(outside);
      }
      setRecords(finalData);
      setExpandedRows([]);

      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const changeSelectOptionHandler = (event) => {
    let report_month = event.target.value;
    setLoaded(false);
    setByMonth(report_month);
    setShowFilter(true);
    setPage(1);
    getAll(fetchContext, 1, report_month, {
      ...filterObject,
      charger_group_id: null,
      export: false,
      site_group_id: null,
      site_id: null,
      date: report_month,
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      charger_group_id: null,
      export: false,
      site_group_id: null,
      site_id: null,
      date: report_month,
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    //setSelected(event.target.value);
  };

  useEffect(() => {
    getAll(fetchContext, page, byMonth, filterObject);
  }, [fetchContext, page]);

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, { search_keyword: null, range: false });
    setFilterObject({
      search_keyword: null,
      range: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    let updatedValues = {
      ...values,
      from_date: values.from_date ? DateTime.fromJSDate(values.from_date).toFormat("dd-MM-yyyy") : null,
      to_date: values.to_date ? DateTime.fromJSDate(values.to_date).toFormat("dd-MM-yyyy") : null,
      range: values.from_date && values.to_date ? true : false,
      date: byMonth,
    };
    getAll(fetchContext, page, byMonth, updatedValues);
    setFilterObject(updatedValues);
  };

  const onShowHideFilter = () => {
    setShowFilter(!showFilter);
    setShowSearch(true);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const onShowHideSearchCancle = () => {
    setShowSearch(true);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const handleNotesView = () => {
    setOpenNotes(true);
  };

  const getSiteWiseData = async (fetchContext, page, byMonth, filterObject, site_id) => {
    try {
      const { data } = await fetchContext.authAxios.post(`/get-revenue-by-site`, {
        date: byMonth,
        export: true,
        from_date: filterObject?.from_date,
        to_date: filterObject?.to_date,
        range: filterObject?.range,
        site_id: site_id,
      });
      //let res = Object.values(data.data);
      setSiteDetail(data?.data);
      handleFilterData(data?.data, byMonth, filterObject, site_id);
      setIsSiteDetailLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const getRound = (value1, value2, value3) => {
    let response = 0;
    if (value2 !== undefined) response = value1 / value2;
    else response = value1 / value2;
    return response;
  };


  const handleFilterData = (site_data, date, { range, to_date, from_date }, site_id) => {
    const dailyData = {};
    let start;
    let end;
    let transactions = site_data;
    if (!transactions) {
      return;
    }

    if (range) {
      let given_to_Date = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      let to_js_date = given_to_Date.toJSDate();

      let given_from_Date = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      let from_js_date = given_from_Date.toJSDate();

      start = DateTime.fromJSDate(from_js_date);
      end = DateTime.fromJSDate(to_js_date);
    } else {
      let givenDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      let startDate = givenDate.toJSDate();
      let endDate = givenDate.endOf("month").toJSDate();

      start = DateTime.fromJSDate(startDate);
      end = DateTime.fromJSDate(endDate);
    }

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        amount: 0,
        usage: 0,
        site_payout: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      const key = DateTime.fromSQL(transaction.created_at).startOf("day").toISO();
      if (transaction?.meter_diff && transaction?.meter_diff / 1000 > 0) {
        if (key && dailyData[key]) {
          dailyData[key].usage += transaction?.meter_diff && transaction?.meter_diff > 0 ? transaction?.meter_diff / 1000 : 0;
        }
      }
      if (key && dailyData[key]) {
        dailyData[key].site_payout += transaction.processingFee.site_payout;
      }
    });

    data[site_id] = Object.values(dailyData).reduce((acc, { count, datetime, amount, usage, site_payout }) => {
      const dt = DateTime.fromMillis(datetime.ts);
      const month = dt.monthLong;
      return [
        ...acc,
        datetime !== undefined && {
          amount: amount.toFixed(2),
          average: count ? getRound(amount, count, "averge") : 0,
          month,
          date: datetime,
          usage: usage.toFixed(2),
          energy_average: count ? getRound(usage, count) : 0,
          site_payout: site_payout,
        },
      ];
    }, []);
    setData(data);
     };

  function formatNumberWithLeadingZero(number) {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  // console.log('sitePayoutCal',sitePayoutCal);

  function Row(props) {
    const { row, onClick, expanded, loaded } = props;
    console.log("row",row);
    
    function formatAllTotal(allTotal) {
      let strTotal = allTotal.toString();
      let decimalIndex = strTotal.indexOf(".");
      if (decimalIndex !== -1) {
        return strTotal.slice(0, decimalIndex + 3); // Include up to two digits after decimal
      } else {
        return strTotal;
      }
    }
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton color="primary" onClick={() => onClick()}>
              {expanded ? (
                <KeyboardArrowUp style={{ fill: "#a31112" }} />
              ) : (
                <KeyboardArrowDown style={{ fill: "#a31112" }} />
              )}
            </IconButton>
          </TableCell>
          <TableCell />
          <TableCell>{row.site_name}</TableCell>
          <TableCell>{row.amount.toFixed(2)}</TableCell>
          <TableCell>{row?.energy ? Number(row?.energy).toFixed(4) : "0.0000"}</TableCell>
          <TableCell>
            {row?.processingFee?.processing_fee
              ? row?.processingFee?.processing_fee.toFixed(2)
              : "0.00"}
          </TableCell>
          <TableCell>
            {row?.processingFee?.licensing_fee
              ? row?.processingFee?.licensing_fee.toFixed(2)
              : "0.00"}
          </TableCell>
          <TableCell>
            {row?.processingFee?.site_payout
              ? row?.processingFee?.site_payout.toFixed(2)
              : "0.00"}
          </TableCell>
          {/* <TableCell>{sitePayoutCal && sitePayoutCal?.[row?.site_id]?.site_payout ? formatAllTotal(sitePayoutCal?.[row?.site_id]?.site_payout) : '0.00'}</TableCell> */}
          <TableCell>{row.total}</TableCell>
          <TableCell>
            {row?.timezone_duration
              ? (Number(row.timezone_duration) / 60 / row.total).toFixed(2)
              : "-"}
          </TableCell>

          <TableCell>
            <Tooltip title="View More">
              <Link
                component={RouterLink}
                to={`/${"reports-sites-next"}/${row.site_id}/${
                  filterObject?.to_date && filterObject?.from_date
                    ? `${filterObject?.from_date}to${filterObject?.to_date}`
                    : byMonth
                }`}
              >
                <InfoOutlined />
              </Link>
            </Tooltip>
          </TableCell>
          <TableCell>
            <ExportSiteWithBreakdown
              filterDate={filterObject}
              byMonth={byMonth}
              selectedSiteId={{
                ...selectedSiteId,
                name: row?.site_name,
                id: row?.site_id,
                amount: Number(row.amount).toFixed(2),
                site_payout: row?.processingFee?.site_payout,
                processing_fee: row?.processingFee?.processing_fee
                  ? row.processingFee?.processing_fee.toFixed(2)
                  : "0.00",
                licensing_fee: row?.processingFee?.licensing_fee
                  ? row?.processingFee?.licensing_fee.toFixed(2)
                  : "0.00",
                energy: Number(row.energy).toFixed(4) || '0.0000',
                charging_duration: row?.timezone_duration
                  ? (row.timezone_duration / 60 / row.total).toFixed(2)
                  : "-",
                number_of_session: row.total,
              }}
              revenueData={data}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              style={{ height: "329px", overflow: "scroll" }}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>kWh Delivered</TableCell>
                    <TableCell>Site Payout</TableCell>
                    <TableCell className={classes.tblCellInside}>
                      {data[row?.site_id]?.length > 0 && (
                        <ExportSiteRevenueBreakdown
                          filterDate={filterObject}
                          byMonth={byMonth}
                          selectedSiteId={selectedSiteId}
                          data={data}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {isSiteDetailLoaded ? (
                  <TableBody>
                    {data[row?.site_id]?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{`${formatNumberWithLeadingZero(
                            item?.date?.c?.month
                          )}/${formatNumberWithLeadingZero(
                            item?.date?.c?.day
                          )}/${item?.date?.c?.year} `}</TableCell>
                          <TableCell>{Number(item.usage).toFixed(4)}</TableCell>
                          <TableCell>
                            $ {Number(item.site_payout).toFixed(2)}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <Loader />
                )}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const toggleRowExpansion = (index, data) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
    setIsSiteDetailLoaded(false);
    // setFilterObject({ ...filterObject, site_id: data?.site_id });
    setSelectedSiteId({ id: data?.site_id, name: data?.site_name });
    getSiteWiseData(fetchContext, page, byMonth, filterObject, data?.site_id);
  };

const getAllTransactions = async (fetchContext, payload) => {
  try {
    const transactions = [];
    let currentPage = 1;
    let totalPages = 1;

    do {
      let updatedPayload = {
        range: payload?.range,
        date: byMonth,
        site_id: filterObject?.site_id,
        site_group_id: filterObject?.site_group_id,
        charger_group_id: filterObject?.charger_group_id,
        to_date: payload?.to_date,
        from_date: payload?.from_date,
        page: currentPage, // Add pagination
      };

      const { data } = await fetchContext.authAxios.post(
        `/get-energy-revenue?page=${currentPage}`,
        updatedPayload
      );

      transactions.push(...data.data); // Append new data
      totalPages = data.totalPages; // Update total pages from API response
      currentPage++; // Increment for the next page
    } while (currentPage <= totalPages);

    setEnergyRevenue(transactions); // Update state with all transactions
  } catch (err) {
    console.log("Error fetching transactions:", err);
  }
};


  useEffect(() => {
    getAllTransactions(fetchContext, { range: filterObject?.range, from_date: filterObject.from_date, to_date: filterObject.to_date });
  }, [fetchContext, filterObject, byMonth]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>Charger Usage Report</div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
              <div onClick={onShowHideFilter}>
                <div className={classes.filterIcon}>
                  {showFilter ? (
                    <Tooltip title="Filter">
                      <FilterAltIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Filter">
                      <FilterAltOffIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.datePicker}>
              <div className={classes.newButton}>
                <MonthRangeSelect
                  label="Filter by Month"
                  name="report_month"
                  value={byMonth}
                  //error={errors.charger_type}
                  required
                  onChange={changeSelectOptionHandler}
                />
              </div>
            </div>
          </div>
        </div>
        {!showSearch && (
          <SessionBySitesFilter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        {!showFilter && (
          <SessionBySitesFilter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        {/* <SessionBySitesGraph byMonth={byMonth} /> */}
        <SiteEnergyUsage records={records}  byMonth={byMonth} filterObject={filterObject} energyRevenue={energyRevenue} />
        <SiteRevenueUsage records={records} byMonth={byMonth} filterObject={filterObject} energyRevenue={energyRevenue} setSitePayoutCal={setSitePayoutCal}/>

        {/* <TblContainer>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside} />
              <TableCell className={classes.tblCellInside}>Site Name</TableCell>
              <TableCell className={classes.tblCellInside}>Amount (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Energy (KWH)</TableCell>
              <TableCell className={classes.tblCellInside}>Average Charging Duration (Minutes)</TableCell>
              <TableCell className={classes.tblCellInside}>Number of Sessions</TableCell>
              <TableCell className={classes.tblCellInside}>{records.length > 0 && <ExportDataBySites filterDate={filterObject} byMonth={byMonth} />}</TableCell>
              <TableCell className={classes.tblCellInside} />
            </TableRow>
          </TableHead>
          {loaded ? (
            <TableBody>
              {records.map((item, index) => (
                <TableRow key={index}>
                  <TableCell />
                  <TableCell>{item.site_name}</TableCell>
                  <TableCell>{Number(item.amount).toFixed(2)}</TableCell>
                  <TableCell>{item?.energy ? Number(item?.energy).toFixed(4) : "0.0000"}</TableCell>
                  <TableCell>{item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-"}</TableCell>
                  <TableCell>{item.total}</TableCell>
                  <TableCell>
                    <Tooltip title="View More">
                      <Link
                        component={RouterLink}
                        to={`/${"reports-sites-next"}/${item.site_id}/${
                          filterObject?.to_date && filterObject?.from_date ? `${filterObject?.from_date}to${filterObject?.to_date}` : byMonth
                        }`}
                      >
                        <InfoOutlined />
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <ExportDataBySitesNext siteId={item.site_id} byMonth={byMonth} filterDate={filterObject} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer> */}

        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tblCellInside} />
                <TableCell className={classes.tblCellInside}>Site Name</TableCell>
                <TableCell className={classes.tblCellInside}>Charged Amount</TableCell>
                <TableCell className={classes.tblCellInside}>Energy (KWH)</TableCell>
                <TableCell className={classes.tblCellInside}>Processing Fee (USD)</TableCell>
                <TableCell className={classes.tblCellInside}>Licensing Fee (USD)</TableCell>
                <TableCell className={classes.tblCellInside}>Site Payout (USD)</TableCell>
                <TableCell className={classes.tblCellInside}>Number of Sessions</TableCell>
                <TableCell className={classes.tblCellInside}>Average Charging Duration (Minutes)</TableCell>

                <TableCell className={classes.tblCellInside}>{records.length > 0 && <ExportDataByChargerUsagesReport filterDate={filterObject} byMonth={byMonth} />}</TableCell>
                {/* <TableCell className={classes.tblCellInside} /> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {loaded ? (
              <TableBody>
                {records?.map((item, index) => {
                  return <Row key={item.name} row={item} onClick={() => toggleRowExpansion(index, item)} expanded={expandedRows[index]} loaded={loaded} />;
                })}
              </TableBody>
            ) : (
              <Loader />
            )}
          </Table>
        </TableContainer>

        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default ChargerUsageReport;