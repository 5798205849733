import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form } from "../../components/useForm";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  responsieScreen: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "600px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "890px",
    },
  },
}));

const TermAndConditionForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    id: 0,
    web_link: "",
  });

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    // Reset error message on change
    setIsError(false);
    setErrorMessage("");
  };

  const validate = () => {
    const urlPattern = /^https:\/\/www\.redecharge\.com\/.+$/; // Ensures the URL starts with the fixed part and has anything after "/"

    if (!urlPattern.test(values.web_link)) {
      setIsError(true);
      setErrorMessage(
        "Please enter a valid Terms and Conditions link starting with: https://www.redecharge.com/"
      );
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
      addOrEdit(values);
      setValues({
        id: 0,
        web_link: "https://www.redecharge.com/terms-conditions",
      });
    // }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit} className={classes.responsieScreen}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controls.Input
            name="web_link"
            label="Terms & Conditions Web Link"
            required
            // value={values.web_link}
            value={"https://www.redecharge.com/terms-conditions"}
            disabled={true}
            onChange={handleInputChange}
            style={{ width: "97.2%" }}
          />
          {isError && (
            <p style={{ color: "red", margin: "5px 0" }}>{errorMessage}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        Do you really want to upgrade the Terms & Conditions page ?
        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={closeForm}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default TermAndConditionForm;
