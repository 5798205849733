import { Grid } from "@material-ui/core";
import { Country } from "country-state-city";
import React, { useContext, useEffect, useRef, useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import SiteGroupsSelect from "../SiteGroups/SiteGroupsSelect";
import ContriesSelect from "./ContriesSelect";
import TimezonesSelect from "./TimezonesSelect";
//import { getTimeZones, rawTimeZones, timeZonesNames } from "@vvo/tzdb";
import { getTimeZones } from "@vvo/tzdb";
import { FetchContext } from "../../context/FetchContext";
import PricePlanSelectAC from "./PricePlanSelectAC";
import PricePlanSelectDC from "./PricePlanSelectDC";
import { useIsDesktop } from "../../context/DisplayContext";
import StationTypeSelect from "./StationTypeSelect";
const countries = Country.getAllCountries();
const timeZones = getTimeZones();

const SiteForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const [siteGroupSelected, setSiteGroupSelected] = useState("");
  const { isDesktop } = useIsDesktop();
  const initialFValues = {
    id: 0,
    name: "",
    coordinates: { latitude: "", longitude: "" },
    country: "US",
    timezone: "America/New_York",
    tax_detail_id: [],
    site_level: "",
    status: true,
  };

  const fetchContext = useContext(FetchContext);
  const [optionValues, setOptionValues] = useState([]);
  //const [ defaultOptionValues, setDefaultOptionValues ] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const getOptionSiteLabel = (option) => option.label;
  const getOptionDisabled = (option) => option.value === 1;
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(optionValues);
    } else {
      handleClearOptions();
    }
  };

  const locationCoordinates = useRef({ latitude: "", longitude: "" });

  //const [ locationCoordinates, setLocationCoordinates ] = useState({"latitude": "","longitude": ""});

  const getDefaultCountry = (ifEdit) => {
    let ret;
    if (ifEdit !== null) {
      ret = recordForEdit.country;
    } else {
      ret = "US";
    }
    return ret;
  };

  const getDefaultTimezone = (ifEdit) => {
    let ret;
    if (ifEdit !== null) {
      ret = recordForEdit.timezone;
    } else {
      ret = "America/New_York";
    }
    return ret;
  };

  const defaultCountry = countries.filter(
    (item) => item?.isoCode === getDefaultCountry(recordForEdit)
  );
  const [selectedOption, setSelectedOption] = useState(defaultCountry[0]);
  //const [selectedOption, setSelectedOption] = useState([]);

  const [inputCountryValue, setInputCountryValue] = useState(""); //inputTimezoneValue
  const [inputTimezoneValue, setInputTimezoneValue] = useState(""); //inputTimezoneValue
  const [defaultTimezone, setDefaultTimezone] = useState(
    timeZones.filter((item) => item.countryCode === selectedOption?.isoCode)
  );
  //const [selectedTimezoneOption, setSelectedTimezoneOption] = useState(defaultTimezone[0]);

  const defaultSelectedTimezone = defaultTimezone.filter(
    (item) => item.name === getDefaultTimezone(recordForEdit)
  );
  const [selectedTimezoneOption, setSelectedTimezoneOption] = useState(
    defaultSelectedTimezone[0]
  );

  const getOptionLabel = (option) => option.name;
  const getTimezoneLabel = (option) =>
    option.currentTimeFormat ? `GMT ${option.currentTimeFormat}` : "";

  const [siteGroups, setSiteGroups] = useState([]);

  const retPricePlanId = (siteGroupId) => {
    let pricePlanId = null;
    if (siteGroups.length > 0) {
      pricePlanId = siteGroups.find(
        (item) => item.id === siteGroupId
      ).price_plan_id;
    }
    return pricePlanId;
  };

  const retTaxRateIds = (siteGroupId) => {
    let taxRateIds = [];
    if (siteGroups.length > 0) {
      taxRateIds = siteGroups
        .find((item) => item.id === siteGroupId)
        .tax_details.map((val) => val.id);
    }
    return taxRateIds;
  };

  const getAllTaxRates = async () => {
    try {
      const { data } = await fetchContext.authAxios.get("/select-tax-details");
      const optionSites = [];
      for (const item of data.data) {
        const optObj = { value: item.id, label: item.name };
        optionSites.push(optObj);
      }
      setOptionValues(optionSites);
      if (recordForEdit != null) {
        let taxRatesIds = recordForEdit.tax_details;
        //let chargingStationIds = [6, 7];
        let defaultOptions = optionSites.filter((item) =>
          taxRatesIds.some((taxIds) => item.value === taxIds.tax_detail_id)
        );
        setSelectedOptions(defaultOptions);
      }
    } catch (err) {
      console.log("the err", err);
    }
  };
  useEffect(() => {
    getAllTaxRates(fetchContext);
  }, [fetchContext, recordForEdit]);

  const getAllSiteGroups = async () => {
    try {
      const { data } = await fetchContext.authAxios.get("/select-site-groups");
      setSiteGroups(data);
    } catch (err) {
      console.log("the err", err);
    }
  };
  useEffect(() => {
    getAllSiteGroups(fetchContext);
  }, [fetchContext]);

 const validate = (fieldValues = values) => {
   let temp = { ...errors };

   // Name: Required first, then validate
   if ("name" in fieldValues) {
     if (!fieldValues.name) {
       temp.name = "This field is required.";
     } else if (!/^[a-zA-Z\d\s]+$/.test(fieldValues.name)) {
       temp.name = "Please enter a valid site name without special characters.";
     } else {
       temp.name = "";
     }
   }

   // Group ID: Required
   if ("group_id" in fieldValues) {
     temp.group_id = fieldValues.group_id ? "" : "Please select a site group.";
   }

   // Address: Required
   if ("address" in fieldValues) {
     temp.address = fieldValues.address ? "" : "Please provide a site address.";
   }

   // City: Required
   if ("city" in fieldValues) {
     temp.city = fieldValues.city ? "" : "Please provide a city name.";
   }

   // State: Required
   if ("state" in fieldValues) {
     temp.state = fieldValues.state ? "" : "Please provide a state name.";
   }

   // Postal Code: Required
   if ("postal_code" in fieldValues) {
     temp.postal_code = fieldValues.postal_code
       ? ""
       : "Please provide a postal code.";
   }

   // Latitude: Required first, then validate format
   if ("latitude" in fieldValues) {
     if (!fieldValues.latitude) {
       temp.latitude = "This field is required.";
     } else if (
       !/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,12})?$/.test(
         fieldValues.latitude
       )
     ) {
       temp.latitude = "Please provide a valid latitude value.";
     } else {
       temp.latitude = "";
     }
   }

   // Longitude: Required first, then validate format
   if ("longitude" in fieldValues) {
     if (!fieldValues.longitude) {
       temp.longitude = "This field is required.";
     } else if (
       !/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,12})?$/.test(
         fieldValues.longitude
       )
     ) {
       temp.longitude = "Please provide a valid longitude value.";
     } else {
       temp.longitude = "";
     }
   }

   // Site Level: Required
   if ("site_level" in fieldValues) {
     temp.site_level = fieldValues.site_level
       ? ""
       : "Please select a site level.";
   }

   setErrors({ ...temp });

   if (fieldValues === values) {
     return Object.values(temp).every((x) => x === "");
   }
 };


  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (recordForEdit != null)
      locationCoordinates.current = recordForEdit.coordinates;

    setValues({
      ...recordForEdit,
    });
  }, [recordForEdit, setValues]);

  const handleSubmit = (e) => {
    e.preventDefault();

    values.coordinates = locationCoordinates.current;
    values.latitude = locationCoordinates?.current?.latitude;
    values.longitude = locationCoordinates?.current?.longitude;

    if (validate()) {
      if (values.status === undefined) {
        values.status = false;
      }

      if (values.country === undefined) {
        values.country = getDefaultCountry(null);
      }

      if (values.timezone === undefined) {
        values.timezone = getDefaultTimezone(null);
      }

      Object.assign(values, { tax_detail_id: [] });
      selectedOptions.forEach((item) => {
        values.tax_detail_id.push(item.value);
      });

      // let tempPricePlan = retPricePlanId(values.group_id);
      // if (values.price_plan_id === undefined && tempPricePlan !== null) {
      //     values.price_plan_id = tempPricePlan;
      // }

      // if (values.ac_price_plan_id === undefined && tempPricePlan !== null) {
      //     values.ac_price_plan_id = tempPricePlan;
      // }

      // let tempTaxRates = retTaxRateIds(values.group_id);
      // if (values.tax_detail_id.length === 0 && tempTaxRates.length > 0) {
      //     values.tax_detail_id = tempTaxRates;
      // }

      addOrEdit(values, resetForm);
    }
  };

  const handleCoordinatesChange = (event) => {
    const { name, value } = event.target;
    validate({ [name]: value });
    //const list = { ...locationCoordinates.current };
    //list[name] = value;
    locationCoordinates.current[name] = value;
  };

  const handleCountrysChange = (event, newVal) => {
    //const { name, value } = event.target;

    if (newVal !== null) {
      setSelectedOption(newVal);
      setDefaultTimezone(
        timeZones.filter((item) => item?.countryCode === newVal?.isoCode)
      );
      values.country = newVal?.isoCode;
      values.currency_code = newVal.currency;
      setSelectedTimezoneOption("");
    }

    //defaultTimezone = timeZones.filter(item => item.countryCode === selectedOption.isoCode);
    //setSelectedTimezoneOption(defaultTimezone[0]);
    //setValue(event.target.value);setSelectedTimezoneOption
  };

  const onCountryInputChange = (event, newVal) => {
    if (newVal !== null) {
      setInputCountryValue(newVal);
    }
  };

  const onTimezoneInputChange = (event, newVal) => {
    if (newVal !== null) {
      setInputTimezoneValue(newVal);
    }
  };

  const handleTimezondeChange = (event, newVal) => {
    //const { name, value } = event.target;
    if (newVal !== null) {
      setSelectedTimezoneOption(newVal);
      values.timezone = newVal.name;
    }

    //setValue(event.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="name"
            label="Site Name"
            required
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
            helperText={errors.name}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <SiteGroupsSelect
            value={values.group_id}
            error={errors.group_id}
            setSiteGroupSelected={setSiteGroupSelected}
            siteGroupSelected={siteGroupSelected}
            allValues={values}
            //required={true}
            //fullWidth={true}
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="address"
            label="Address"
            required
            value={values.address}
            onChange={handleInputChange}
            error={errors.address}
            helperText={errors.address}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>

        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="city"
            label="City"
            required
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
            helperText={errors.city}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="state"
            label="State"
            required
            value={values.state}
            onChange={handleInputChange}
            error={errors.state}
            helperText={errors.state}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="postal_code"
            label="Postal Code"
            required
            value={values.postal_code}
            onChange={handleInputChange}
            error={errors.postal_code}
            helperText={errors.postal_code}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <ContriesSelect
            //name="country"
            options={countries}
            getOptionLabel={getOptionLabel}
            //getOptionDisabled={getOptionDisabled}
            selectedOption={selectedOption}
            onChange={handleCountrysChange}
            //error={errors.country}
            inputValue={inputCountryValue}
            onInputChange={onCountryInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TimezonesSelect
            //name="timezone"
            options={defaultTimezone}
            getOptionLabel={getTimezoneLabel}
            //getOptionDisabled={getOptionDisabled}
            selectedOption={selectedTimezoneOption}
            onChange={handleTimezondeChange}
            //error={errors.timezone}
            inputValue={inputTimezoneValue}
            onInputChange={onTimezoneInputChange}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="latitude"
            label="Latitude"
            required
            value={locationCoordinates.current.latitude}
            onChange={(e) => handleCoordinatesChange(e)}
            error={errors.latitude}
            helperText={errors.latitude}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="longitude"
            label="Longitude"
            required
            //type="number"
            value={locationCoordinates.current.longitude}
            onChange={(e) => handleCoordinatesChange(e)}
            error={errors.longitude}
            helperText={errors.longitude}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>

        <Grid item xs={6}>
          <Controls.MultiSelect
            items={optionValues}
            getOptionLabel={getOptionSiteLabel}
            getOptionDisabled={getOptionDisabled}
            selectedValues={selectedOptions}
            label={"Select Tax Rates"}
            placeholder={"Placeholder for Tax Rates"}
            selectAllLabel="Select all"
            onToggleOption={handleToggleOption}
            onClearOptions={handleClearOptions}
            onSelectAll={handleSelectAll}
          />
        </Grid>
        <Grid item xs={6}>
          <StationTypeSelect
            value={values.site_level}
            error={errors.site_level}
            siteGroupSelected={siteGroupSelected}
            recordForEdit={recordForEdit && recordForEdit?.id}
            required
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PricePlanSelectDC
            value={values.price_plan_id}
            error={errors.price_plan_id}
            siteGroupSelected={siteGroupSelected}
            recordForEdit={recordForEdit && recordForEdit?.id}
            //required
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PricePlanSelectAC
            value={values.ac_price_plan_id}
            error={errors.ac_price_plan_id}
            siteGroupSelected={siteGroupSelected}
            recordForEdit={recordForEdit && recordForEdit?.id}
            //required
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="meter_number"
            label="Meter Number"
            value={values.meter_number}
            onChange={handleInputChange}
            error={errors.meter_number}
            helperText={errors.meter_number}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="account_number"
            label="Utility Account Number"
            value={values.account_number}
            onChange={handleInputChange}
            error={errors.account_number}
            helperText={errors.account_number}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="utility_transformer_capacity"
            label="Utility"
            value={values.utility_transformer_capacity}
            onChange={handleInputChange}
            error={errors.utility_transformer_capacity}
            helperText={errors.utility_transformer_capacity}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="utility_service_capacity"
            label="Utility Service Capacity (Total power)"
            value={values.utility_service_capacity}
            onChange={handleInputChange}
            error={errors.utility_service_capacity}
            helperText={errors.utility_service_capacity}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Checkbox
            name="status"
            label="Visible on Map"
            value={values.status}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={closeForm}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SiteForm;
