import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { FetchContext } from "../../context/FetchContext";
//import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Typography } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
} from "@material-ui/core";

import { makeStyles, Paper } from "@material-ui/core";
//import { curveCardinal } from 'd3-shape';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useIsDesktop } from "../../context/DisplayContext";
import { DateTime } from "luxon";
import { formatMaxPower } from "../../components/FormatMaxPower";

const SessionsPopUp = (props) => {
  const { transactionData } = props;
  //const timezone = transactionData?.connector?.charging_stations?.site.timezone;
  const timezone = transactionData?.timezone;
  //const manufacturer_id = transactionData?.connector?.charging_stations?.manufacturer_id;
  const manufacturer_id = transactionData?.manufacturer_id;
  const classes = useStyles();
  //const [ sessions, setSessions] = useState([]);
  const [meterValues, setMeterValues] = useState([]);
  const [fileMeterValues, setFileMeterValues] = useState([]);
  const fetchContext = useContext(FetchContext);
  const [currentSoc, setCurrentSoc] = useState("");
  const [currentVoltage, setCurrentVoltage] = useState("");
  const [currentPower, setCurrentPower] = useState("");
  const [maxValue, setMaxValue] = useState(500);
  const [graphData, setGraphData] = useState([]);
  const [tempMeterData, setTempMeterData] = useState(undefined);
  const [tabelData, setTabelData] = useState();
  const [transactionStatus, setTransactionStatus] = useState("ACTIVE");
  const { isDesktop } = useIsDesktop();
  const searchFileName = transactionData?.ocpp_cbid
    ? `${transactionData.ocpp_cbid}_${transactionData.sequence_number}_${transactionData.id}_metervalues.json`
    : "";
  // const searchFileName = transactionData?.connector
  //   ? `${transactionData.connector.charging_stations.ocpp_cbid}_${transactionData.connector.sequence_number}_${transactionData.id}_metervalues.json`
  //   : "";
  const convertFileTimeZone = (timestamp) => {
    // const utcDateTime = DateTime.fromISO(timestamp, { zone: 'utc' });
    // const localDateTime = timestamp.setZone(timezone);
    // const formattedDateTime = localDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
    // const timePart = formattedDateTime.substr(11, 8);
    // return timePart;
    // Parse the UTC date-time and convert it to the specified timezone
    const utcDateTime = DateTime.fromISO(timestamp, { zone: "utc" }).setZone(
      timezone
    );
    // Extract hours and minutes from the time part
    const formattedTime = utcDateTime.toFormat("HH:mm:ss"); // Only hours and minutes
    return formattedTime;
  };

  const convertTimeZone = (timestamp) => {
    // Parse the UTC date-time and convert it to the specified timezone
    const utcDateTime = DateTime.fromSQL(timestamp, { zone: "utc" }).setZone(
      timezone
    );
    // Extract hours and minutes from the time part
    const formattedTime = utcDateTime.toFormat("HH:mm:ss"); // Only hours and minutes
    return formattedTime;
  };

  const getMeterValuesFromFile = async (fileName) => {
    try {
      const { data } = await fetchContext.authAxios.post(`/read-from-bucket`, {
        file_name: fileName,
        //file_name: "0910040100000500000000620068_1_43313_metervalues.json",
      });
      if (data.data) {
        let temp = data.data;
        // console.log('tempdatafrom file: ', data);
        // console.log('tempdatafrom file data.data: ', data.data);
        if (temp?.length > 0) {
          setTempMeterData(temp);
          setFileMeterValues(temp);
          //setMeterValues(temp[temp.length - 1].sampledValue);

          let tempSoc = 0;
          let tempPower = 0;
          let tempVoltage = 0;
          let listFinished = [];

          for (let i = 0; i < temp.length; i++) {
            let tempSampleValue = temp[i].sampledValue;
            let timezone = temp[i]?.timestamp;
            for (let s = 0; s < tempSampleValue.length; s++) {
              if (tempSampleValue[s].measurand === "SoC") {
                tempSoc = tempSampleValue[s].value;
              }
              if (tempSampleValue[s].measurand === "Power.Active.Import") {
                if (tempSampleValue[s].value > 0) {
                  tempPower = parseInt(tempSampleValue[s].value);
                  tempPower = tempPower.toFixed(2);
                } else {
                  tempPower = 0;
                }
                //tempPower = parseInt(tempSampleValue[s].value)/1000;
              }
              if (tempSampleValue[s].measurand === "Voltage") {
                tempVoltage = tempSampleValue[s].value;
              }
            }
            let lestObj = {
              name: convertFileTimeZone(timezone),
              soc: tempSoc,
              power: tempPower,
              voltage: tempVoltage,
            };
            listFinished.push(lestObj);
          }

          setGraphData(listFinished);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMeterValuesFromFile(searchFileName);
  }, [searchFileName]);

  useEffect(() => {
    let currentValueId = 0;
    const abortCtrl = new AbortController();
    const abortCtrlOpts = { signal: abortCtrl.signal };

    const getCurrentValues = () => {
      try {
        fetchContext.authAxios
          .get(`/transactions/${transactionData.id}`, abortCtrlOpts)
          .then((data) => {
            if (abortCtrl.signal.aborted) {
              return;
            }
            
            setTabelData(data.data.data[0]);
            setCurrentSoc(data.data.data[0].soc_end);
            setCurrentPower(data.data.data[0].current_power);
            setCurrentVoltage(data.data.data[0].current_voltage);
            if (data.data.data[0].status === "FINISHED") {
              setTransactionStatus("FINISHED");
              //setSessions(data.data.data);
              //let temp = JSON.parse(data.data.meter_data);
              let temp = data.data.data[0].meter_data;
              if (temp !== null) {
                //setMeterValues(temp[temp.length - 1].sampledValue);
                setMeterValues(temp);
              }

              let tempSoc = 0;
              let tempPower = 0;
              let tempVoltage = 0;
              let listFinished = [];
              let meterValueTimestamp;
              if (temp) {
                let tempSampleValue = temp;
                for (let s = 0; s < tempSampleValue.length; s++) {
                  meterValueTimestamp = temp[s]?.created_at;
                  tempSoc = tempSampleValue[s].soc;

                  if (tempSampleValue[s].power_active_import > 0) {
                    tempPower =
                      parseInt(tempSampleValue[s].power_active_import);
                    tempPower = tempPower.toFixed(2);
                  } else {
                    tempPower = 0;
                  }

                  tempVoltage = tempSampleValue[s].voltage;
                  tempVoltage = tempVoltage?.toFixed(2);

                  let lestObj = {
                    //name: DateTime.fromISO(meterValueTimestamp).toFormat("HH:mm:ss"),
                    name: convertTimeZone(meterValueTimestamp),
                    soc: tempSoc,
                    power: tempPower,
                    voltage: tempVoltage,
                  };
                  listFinished.push(lestObj);
                }
              }
              //console.log('listFinished-setGraphData', listFinished);
              const maxPower = listFinished.reduce((max, obj) => {
                const power = parseFloat(obj.power);
                return power > max ? power : max;
              }, 0);

              const maxVoltage = listFinished.reduce((max, obj) => {
                const voltage = parseFloat(obj.voltage);
                return voltage > max ? voltage : max;
              }, 0);

              maxPower > maxVoltage
                ? setMaxValue(maxPower + 50)
                : setMaxValue(maxVoltage + 50);

              setGraphData(listFinished);
            }

            if (data.data.data[0].status === "ACTIVE") {
              setTransactionStatus("ACTIVE");
              let temp = data.data.data[0].meter_data;
              if (temp !== null) {
                //setMeterValues(temp[temp.length - 1].sampledValue);
                setMeterValues(temp);
              }

              let tempSoc = 0;
              let tempPower = 0;
              let tempVoltage = 0;
              let listActive = [];
              let meterValueTimestamp;
              if (temp) {
                let tempSampleValue = temp;
                for (let s = 0; s < tempSampleValue.length; s++) {
                  meterValueTimestamp = temp[s]?.created_at;
                  tempSoc = tempSampleValue[s].soc;

                  if (tempSampleValue[s].power_active_import > 0) {
                    tempPower =
                      parseInt(tempSampleValue[s].power_active_import) ;
                    tempPower = tempPower.toFixed(2);
                  } else {
                    tempPower = 0;
                  }

                  tempVoltage = tempSampleValue[s].voltage;
                  tempVoltage = tempVoltage.toFixed(2);

                  let lestObj = {
                    //name: DateTime.fromISO(meterValueTimestamp).toFormat("HH:mm:ss"),
                    name: convertTimeZone(meterValueTimestamp),
                    soc: tempSoc,
                    power: tempPower,
                    voltage: tempVoltage,
                  };
                  listActive.push(lestObj);
                }
              }
              //console.log('listFinished-setGraphData', listFinished);
              const maxPower = listActive.reduce((max, obj) => {
                const power = parseFloat(obj.power);
                return power > max ? power : max;
              }, 0);

              const maxVoltage = listActive.reduce((max, obj) => {
                const voltage = parseFloat(obj.voltage);
                return voltage > max ? voltage : max;
              }, 0);

              maxPower > maxVoltage
                ? setMaxValue(maxPower + 50)
                : setMaxValue(maxVoltage + 50);

              setGraphData(listActive);
              currentValueId = setTimeout(getCurrentValues, 10000);
            } else {
              if (abortCtrl.signal.aborted) {
                return;
              }
              clearTimeout(currentValueId);
            }
          });
      } catch (err) {
        if (abortCtrl.signal.aborted) {
          return;
        }
        clearTimeout(currentValueId);
        console.log("the err", err);
      }
    };

    if (tempMeterData === undefined) {
      getCurrentValues();
    }

    return () => {
      abortCtrl.abort();
      if (currentValueId) {
        clearTimeout(currentValueId);
      }
    };
  }, [fetchContext.authAxios]);

  useEffect(() => {
    if (meterValues) {
      for (let i = 0; i < meterValues.length; i++) {
        setCurrentSoc(meterValues[i].soc);

        if (meterValues[i].power_active_import > 0) {
          let tempPower = parseInt(meterValues[i].power_active_import);
          tempPower = tempPower.toFixed(2);
          setCurrentPower(tempPower);
        } else {
          setCurrentPower(0);
        }

        let tempVoltage = meterValues[i].voltage;
        tempVoltage = tempVoltage?.toFixed(2);
        setCurrentVoltage(tempVoltage);
      }
    } else {
      for (let i = 0; i < fileMeterValues.length; i++) {
        if (fileMeterValues[i].measurand === "SoC") {
          setCurrentSoc(fileMeterValues[i].value);
        }
        if (fileMeterValues[i].measurand === "Power.Active.Import") {
          if (fileMeterValues[i].value > 0) {
            let tempPower = parseInt(fileMeterValues[i].value);
            tempPower = tempPower.toFixed(2);
            setCurrentPower(tempPower);
          } else {
            setCurrentPower(0);
          }
        }
        if (fileMeterValues[i].measurand === "Voltage") {
          setCurrentVoltage(fileMeterValues[i].value);
        }
      }
    }
    // if (graphData) {
    //   const maxPower = graphData.reduce((max, obj) => {
    //     const power = parseFloat(obj.power);
    //     return power > max ? power : max;
    //   }, 0);

    //   const maxVoltage = graphData.reduce((max, obj) => {
    //     const voltage = parseFloat(obj.voltage);
    //     return voltage > max ? voltage : max;
    //   }, 0);

    //   // const nextPMultiple = Math.ceil(maxPower / 100) * 100;
    //   // const nextVMultiple = Math.ceil(maxVoltage / 100) * 100;

    //   // nextPMultiple > nextVMultiple
    //   //   ? setMaxValue(nextPMultiple)
    //   //   : setMaxValue(nextVMultiple);
    //   maxPower > maxVoltage
    //     ? setMaxValue(maxPower + 100)
    //     : setMaxValue(maxVoltage + 100);
    // }
  }, [meterValues]);

  return (
    <>
      <Grid container fullWidth>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>First SOC</TableCell>
              <TableCell>
                {transactionStatus === "ACTIVE" ? "Current" : "Last"} SOC
              </TableCell>
              <TableCell>Max Power</TableCell>
              <TableCell>
                {transactionStatus === "ACTIVE" ? "Current" : "Last"} Voltage
              </TableCell>
              <TableCell>
                {transactionStatus === "ACTIVE" ? "Current" : "Last"} Power
              </TableCell>
              <TableCell>Session Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {tabelData?.soc_start ? `${tabelData?.soc_start} %` : ""}
              </TableCell>
              <TableCell>{currentSoc ? `${currentSoc} %` : ""}</TableCell>
              {/* {transactionStatus === "ACTIVE" ? <>
                {tabelData?.active_max_power > 0 ? (manufacturer_id === 10 || manufacturer_id === 4 ? (<TableCell>{parseInt(tabelData?.active_max_power)}</TableCell>) : (<TableCell>{parseInt(tabelData?.active_max_power) / 1000}</TableCell>)) : (<TableCell>0</TableCell>)}
              </> 
                : <>
                {tabelData?.max_power > 0 ? (manufacturer_id === 10 || manufacturer_id === 4 ? (<TableCell>{parseInt(tabelData?.max_power)}</TableCell>) : (<TableCell>{parseInt(tabelData?.max_power) / 1000}</TableCell>)) : (<TableCell>0</TableCell>)}
                </> 
              } */}
              <TableCell>
                {transactionStatus === "ACTIVE"
                  ? formatMaxPower(tabelData?.active_max_power, manufacturer_id)
                  : formatMaxPower(tabelData?.max_power, manufacturer_id)}
              </TableCell>

              <TableCell>
                {currentVoltage ? `${currentVoltage} V` : ""}
              </TableCell>
              <TableCell>{currentPower ? `${currentPower} KW` : ""}</TableCell>
              <TableCell>
                {tabelData?.session_start && tabelData?.session_end
                  ? `${DateTime.fromSQL(tabelData?.session_end)
                      .diff(DateTime.fromSQL(tabelData?.session_start))
                      .toFormat("hh:mm:ss")}`
                  : "--"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableRow>
              <TableCell>
                <Typography gutterBottom>
                  <span className={classes.txtBlue}>Session Summary</span>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className="forSesionGraph">
                  <div className="responsiveCharts">
                    <ResponsiveContainer key={`ck_${{ currentSoc }}`}>
                      <LineChart
                        data={graphData}
                        key={`ck_${{ currentSoc }}`}
                        margin={{
                          top: 0,
                          right: 3,
                          left: 3,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          angle={-45}
                          orientation={"bottom"}
                          dy={22}
                          dx={-15}
                        />
                        <YAxis domain={[0, maxValue]} tickCount={11} />
                        {/* <YAxis domain={[0, 500]} tickCount={11} /> */}
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="voltage"
                          stroke="#a31112"
                          dy={12}
                        />
                        <Line
                          type="monotone"
                          dataKey="power"
                          stroke="#305eac"
                          dy={12}
                        />
                        <Line
                          type="monotone"
                          dataKey="soc"
                          stroke="#faa51a"
                          dy={12}
                        />
                      </LineChart>
                      {/* <BarChart
                      width={820}
                      height={120}
                      data={graphData}
                      margin={{
                        top: 5,
                        right: 3,
                        left: 3,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend wrapperStyle={{top: 110, left: 25}} />
                      <Bar dataKey="soc" fill="#faa51a" />
                      <Bar dataKey="power" fill="#305eac" />
                      <Bar dataKey="voltage" fill="#a31112" />
                    </BarChart> */}
                    </ResponsiveContainer>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default SessionsPopUp;
const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(1), // Prevent the FAB overlapping pagination elements
    marginBottom: theme.spacing(1), // Prevent the FAB overlapping pagination elements
    //width: '99%',
    //paddingleft: theme.spacing(2), // Prevent the FAB overlapping pagination elements
  },
  txtOrange: {
    color: "#faa51a",
    fontWeight: 700,
  },
  txtBlue: {
    color: "#a70f11",
    fontWeight: 500,
  },
  chartResponsive: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: 320,
      height: 230,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 320,
      height: 230,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 320,
      height: 230,
    },
  },
  tableActions: {
    paddingRight: 0,
    whiteSpace: "nowrap",
    width: 1,
    "& > button": {
      marginRight: theme.spacing(2),
    },
  },
}));
