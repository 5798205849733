import React, { useState, useEffect } from "react";
//import { FetchContext } from '../../context/FetchContext';
import { Table, TableCell, TableRow } from "@material-ui/core";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateTime } from "luxon";

const Energy = ({ transactions, start, end }) => {
  const [data, setData] = useState();
  const [maxValue, setMaxValue] = useState(250);

  useEffect(() => {
    if (!transactions) {
      return;
    }

    // const dailyData = {
    //   datetime: DateTime,
    //   count: 0,
    //   usage: 0
    // };

    const dailyData = {};

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        usage: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      // if (!transaction.meter_end || !transaction.meter_start) {
      //   return;
      // }
      if ((transaction?.meter_diff) / 1000 > 0) {
        const key = DateTime.fromSQL(transaction.graph_date).startOf("day").toISO();
        if (key && dailyData[key]) {
          dailyData[key].count++;
          dailyData[key].usage += (transaction?.meter_diff) / 1000;
        }
      }
    });

    setData(
      Object.values(dailyData).reduce((acc, { count, datetime, usage }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;
        return [
          ...acc,
          datetime !== undefined && {
            usage: usage.toFixed(4),
            average: count ? getRound(usage, count) : 0,
            month,
            count,
            //date: datetime.toLocaleString(DateTime.DATE_SHORT),
            date: datetime.day,
          },
        ];
      }, [])
    );
  }, [end, start, transactions]);

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(4);
    else response = value1 / value2;

    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allCount = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        if (Number(d.usage) > 0) {
          allTotal = allTotal + parseFloat(d.usage);
          // allCount = allCount + parseFloat(d.count);
        }
      }
    }

    final_average = allTotal > 0 ? (allTotal / number_of_days).toFixed(4) : "0.0000";
    return {
      allTotal: allTotal.toFixed(4),
      // allCount: allCount.toFixed(2),
      average: final_average,
    };
  };

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.usage);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 100) * 100;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>
          <p className="py-0 my-0 text-danger">{`Total (kWh) : ${payload[0].value}`}</p>
          <p className="py-0 my-0" style={{ color: "#01b2aa" }}>{`Average (kWh) : ${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };

  // function formatYAxis(value) {
  //   if(value === 0) return "No"
  //   if(value === 1) return "Yes"
  //   return value
  // }

  return (
    <>
      <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
        <div class="card-body pb-5">
          <div class="card-header row" style={{ background: "#fff" }}>
            <div class="col-md-6">
              <strong class="card-title">Energy Usage </strong> <span class="card-title">(in kilowatt-hour)</span>{" "}
            </div>
            <div class="col-md-3 totalkwh">
              Total <span>{getTotal(data).allTotal}</span>{" "}
            </div>
            <div class="col-md-3 totalkwh">
              {" "}
              Average <span>{getTotal(data).average}</span>
            </div>
          </div>
          <div class="clearfix"></div>
          <Table>
            <TableRow>
              <TableCell>
                <div className="responsiveCharts">
                  <ResponsiveContainer>
                    <LineChart
                      // width={560}
                      // height={270}
                      data={data}
                      margin={{
                        top: 0,
                        right: 0,
                        left: 10,
                        bottom: -20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis dataKey="date" />
                      <YAxis domain={[0, maxValue]} tickCount={11} />
                      {/* <YAxis interval="preserveStartEnd" domain={[0, 500]} tickFormatter={formatYAxis} /> */}
                      <Line
                        type="monotone"
                        dataKey="usage"
                        name="Total (kWh)"
                        stroke="#a31135"
                        strokeWidth={1}
                        dot={false}
                        //activeDot={{ r: 7 }}
                      />

                      <Line type="monotone" dataKey="average" name="Average (kWh)" stroke="#01b2aa" strokeWidth={1} dot={false} />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend wrapperStyle={{ position: "relative" }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Energy;
