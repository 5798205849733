import React from 'react';
//import { Grid } from '@material-ui/core';
//import { Box, Grid } from "@mui/material";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import Controls from "../../components/controls/Controls";
import { Form, useForm } from '../../components/useForm';
import SitesGroupSelect from '../../pages/Sessions/SitesGroupSelect';
import ChargerTypeSelect from './ChargerTypeSelect';
import ChargingStationStatusSelect from './ChargingStationStatusSelect';
import ManufacturersSelect from './ManufacturersSelect';
import SitesSelect from './SitesSelect';
import { useIsDesktop } from '../../context/DisplayContext';
import ChargerStageSelect from './ChargerStageSelect';

const Filter = (props) => {
  const { onFilter, oncancle, filterObject } = props
  const user = localStorage.getItem("chstF");
  const { isDesktop } = useIsDesktop();

  const initialFValues = {
    "site_id": filterObject.site_id,
    "site_group_id": filterObject.site_group_id,
    "ocpp_cbid": filterObject.ocpp_cbid,
    "payment_interface": filterObject.payment_interface,
    "connector_status": filterObject.connector_status,
    "manufacturer_id": filterObject.manufacturer_id,
    "search_keyword": filterObject.search_keyword,
    "charger_type": filterObject.charger_type,
    "is_search": props.showSearch,
    "session_id": null
    //"export":filterObject.export
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    //if ('name' in fieldValues)
    //temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const handleSubmit = e => {
    e.preventDefault()
    if (validate()) {
      // if(!values.session_type) { values.session_type = filterObject.session_type };
      // if(!values.site_id) { values.site_id = filterObject.site_id };
      // if(!values.session_id) { values.session_id = filterObject.session_id };
      onFilter(values);
    }
  }

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Divider style={{ width: "100%", color: "#808080" }} />
        {props.showSearch ? (
          <>
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.Input
                name="ocpp_cbid"
                label="OCPP CBID"
                //required
                value={values.ocpp_cbid}
                onChange={handleInputChange}
                error={errors.ocpp_cbid}
                //fullWidth
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <SitesSelect
                //label="Sites"
                //name="site_id"
                value={values.site_id}
                error={errors.site_id}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 12}>
              <SitesGroupSelect
                //label="Sites"
                //name="site_id"
                value={values.site_group_id}
                error={errors.site_group_id}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
            {/* <Grid item xs={isDesktop ? 3 : 12} >
            <Controls.Input
              name="payment_interface"
              label="Payment Interface"
              //required
              value={values.payment_interface}
              onChange={handleInputChange}
              error={errors.payment_interface}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid> */}
            <Grid item xs={isDesktop ? 3 : 12}>
              <ChargingStationStatusSelect
                label="Charging Station Status"
                name="connector_status"
                value={values.connector_status}
                error={errors.connector_status}
                //required
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={isDesktop ? 3 : 12}>
              <ChargerTypeSelect
                label="Charger Type"
                name="charger_type"
                value={values.charger_type}
                error={errors.charger_type}
                onChange={handleInputChange}
                style={{ width: "95%" }}
              />
            </Grid>

            <Grid item xs={isDesktop ? 3 : 12}>
              <ChargerStageSelect
                label="Charger Stage"
                name="charger_stage"
                value={values?.charger_stage}
                error={errors.charger_stage}
                onChange={handleInputChange}
                filter={true}
                isrequired={false}
                // recordForEdit={recordForEdit && recordForEdit?.id}
              />
            </Grid>

            {/* {user === "true" ? (
            <Grid item xs={isDesktop ? 3 : 12}>
              <ManufacturersSelect
                label="Charger Manufacturer"
                name="manufacturer_id"
                value={values.manufacturer_id}
                error={errors.manufacturer_id}
                required={false}
                filter={true}
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
          ) : (
            ""
          )} */}
          </>
        ) : (
          <Grid item xs={isDesktop ? 3 : 12}>
            <Controls.Input
              name="search_keyword"
              label="Search"
              value={values.search_keyword}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </Grid>
        )}

        <Grid item xs={isDesktop ? 3 : 12}>
          <div>
            <Controls.Button
              type="submit"
              text={!props.showSearch ? "Search" : "Filter"}
              size="small"
            />
            <Controls.Button
              text="Cancel"
              color="default"
              size="small"
              onClick={oncancle}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
    // </Box>
  );
}

export default Filter;
