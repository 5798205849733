import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";

const StationTypeSelect = (props) => {
  const {
    recordForEdit,
    value = "",
    error = null,
    onChange,
    siteGroupSelected = null,
  } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  const [stationType, setstationType] = useState(value);

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.post("/get-select-inputs",{type:'site_level'});
        if (data.data && data.data.length > 0) {
          let temp = [ ...data.data];
          setValues(temp);
          setLoaded(true);
        }
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllSites(fetchContext);
  }, [fetchContext]);

  useEffect(() => {
    if (siteGroupSelected.site_level) {
      setstationType(siteGroupSelected.site_level);
    }
    if (value) {
      setstationType(value);
    }
  }, [siteGroupSelected, value]);

  return (
    <>
      {loaded ? (
        <Controls.Select
          name="site_level"
          label="Site Level"
          value={stationType}
          // value={value}
          required={true}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "100%" }}
        />
      ) : (
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input
          name="site_level"
          label="Site Level"
          style={{ width: "97.5%" }}
        >
          <CircularProgress size="1rem" />{" "}
        </Controls.Input>
      )}
    </>
  );
};

export default StationTypeSelect;
