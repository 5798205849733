import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { ImportExport } from '@material-ui/icons';
import { CircularProgress, Snackbar, Tooltip } from '@mui/material';
import { formatCreatedAt } from '../../components/FormatPrice';

const ExportData = ({ filterObject, showSearch}) => {
  const fetchContext = useContext(FetchContext);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display

  function fetchConvertedDate(dateValue, timezone) {
    const dt = DateTime.fromISO(dateValue.replace(" ", "T"), { zone: "UTC" });

    const dtConverted = dt.setZone(timezone);
    return (
      dtConverted.toLocaleString(DateTime.DATE_SHORT) +
      " " +
      dtConverted.toLocaleString(DateTime.TIME_SIMPLE)
    );
  }

  function formatDateToMMDDYYYY(dateString) {
    const dt = DateTime.fromISO(dateString.replace(" ", "T"), { zone: "UTC" });
    return dt.toFormat("MM/dd/yyyy");
  }
  function formatDateWithTime(dateString) {
    const dt = DateTime.fromISO(dateString.replace(" ", "T"), { zone: "UTC" });
    return dt.toFormat("MM/dd/yyyy hh:mm:ss"); // Format to MM/DD/YYYY hh:mm:ss
  }

  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    const dataArr = [];
    try {
      const { data } = await fetchContext.authAxios.post(
        `get-filter-charging-stations`,
        {
          site_id:
            filterObject.site_id !== "None" ? filterObject.site_id : null,
          site_group_id:
            filterObject.site_group_id !== "None"
              ? filterObject.site_group_id
              : null,
          ocpp_cbid: filterObject.ocpp_cbid,
          payment_interface: filterObject.payment_interface,
          connector_status:
            filterObject.connector_status !== "None"
              ? filterObject.connector_status
              : null,
          charger_stage:
            filterObject.charger_stage !== "None"
              ? filterObject.charger_stage
              : null,
          manufacturer_id:
            filterObject.manufacturer_id !== "None"
              ? filterObject.manufacturer_id
              : null,
          search_keyword: filterObject.search_keyword,
          charger_type: filterObject.charger_type,
          is_search: !showSearch,
          export: true,
        }
      );

      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          // Extract date and time from updated_at
          let [date, time] = item.updated_at.split(" ");

          const formattedTime = new Date(`1970-01-01T${time}`).toLocaleString(
            "en-US",
            {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false, // 24-hour format
            }
          );

          // Create the object with the correct fields
          let obj = {
            chargingStation: item.name,
            ocppCbid: item.ocpp_cbid,
            site: item.site_name ? item.site_name : "",
            site_group: item.site_group_name,
            charger_type: item.charger_type,
            charger_stage:item?.charger_stage,
            charger_output: item.charger_output,
            installed_at: item.installed_at
              ? formatDateWithTime(item.installed_at)
              : "",
            commissioned_at: item.commissioned_at
              ? formatDateWithTime(item.commissioned_at)
              : "",
            last_boot_at: item.last_boot_at
              ? formatDateWithTime(item.last_boot_at)
              : "",
            version: item.version || "",
            owner: item.owner || "",
            status: item.charger_status || "", // Use charger_status as the status
            status_date: formatDateToMMDDYYYY(date), // Date from updated_at
            status_time: formattedTime || "", // Formatted time from updated_at
            serial_number: item.serial_number || "",
          };

          dataArr.push(obj);
        }
      }
      setLoading(false); // Set loading to false after fetching data
      setShowMessage(false); // Show message when starting download

      return dataArr;
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return an empty array in case of an error to avoid breaking the download
    }
  };

  const columns = [
    { id: "chargingStation", displayName: "Charging Station" },
    { id: "ocppCbid", displayName: "EVSE Serial Number" },
    { id: "site", displayName: "Site Name" },
    { id: "site_group", displayName: "Site Account" },
    { id: "charger_type", displayName: "Charger Type" },
    { id: "charger_stage", displayName: "Charger Stage" },
    { id: "charger_output", displayName: "Charger Output" },
    { id: "status", displayName: "EVSE Status" },
    { id: "status_date", displayName: "Effective Status Date" },
    { id: "status_time", displayName: "Effective Status Time" },
    { id: "installed_at", displayName: "Installed At" },
    { id: "commissioned_at", displayName: "Comissioned At" },
    { id: "last_boot_at", displayName: "LastBoot At" },
    { id: "version", displayName: "FW Version" },

    { id: "owner", displayName: "Owner" },
    // { 'id': 'manufacturer', 'displayName': 'Manufacturer' },
    { id: "serial_number", displayName: "Serial Number" },
  ];

  return (
    <>
      <CsvDownloader
        filename="ChargingStations"
        extension=".csv"
        // separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default ExportData;