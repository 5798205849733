import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Controls from "./../../components/controls/Controls";
import { FetchContext } from "../../context/FetchContext";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "../../components/Notification";

export default function ConnectorsModal({
  handleRemoteStart,
  handleRemoteStop,
  remoteStartSequenceNumber,
  setRemoteStartSequenceNumber,
  remoteStopSequenceNumber,
  setRemoteStopSequenceNumber,
  chargingStation,
  remoteStartDialog,
  setRemoteStartDialog,
  remoteStopDialog,
  setRemoteStopDialog,
  isStopMode = false,
  setNotify,
  notify,
}) {
  const [selectedConnectorId, setSelectedConnectorId] = React.useState(null);
  const [open, setOpen] = React.useState(
    isStopMode ? remoteStopDialog : remoteStartDialog
  );
  const [connectors, setConnectors] = React.useState([]);
  const fetchContext = React.useContext(FetchContext);

  const handleClose = () => {
    setOpen(false);
    if (isStopMode) {
      setRemoteStopDialog(false);
    } else {
      setRemoteStartDialog(false);
    }
  };

  const handleSubmit = () => {
    if (isStopMode) {
      fetchContext.authAxios
        .post(`/remote-stop`, {
          charger_id: chargingStation?.id,
          connector_id: selectedConnectorId,
        })
        .then(({ data }) => {
          if (data.status) {
            console.log("dataa connec",data.data)
            handleRemoteStop(
              chargingStation.ocpp_cbid,
              data.data,
              // chargingStation.transactionId,
              remoteStopSequenceNumber,
            );
          } else {
            setNotify({
              isOpen: true,
              message: `Charging session can't be ended remotely as there is no active session`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again: ${err.message}`,
            type: "error",
          });
        });
      setRemoteStopDialog(false);
    } else {
      handleRemoteStart(chargingStation.ocpp_cbid, remoteStartSequenceNumber);
      setRemoteStartDialog(false);
    }
  };

  const getStatus = () => {
    fetchContext.authAxios
      .get(`/charging-station-connectors/${chargingStation.id}`)
      .then(({ data }) => {
        setConnectors(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <FormControl className="w-100">
        <Typography variant="h6" component="div">
          Select Connector
        </Typography>
        <RadioGroup
          value={
            isStopMode ? remoteStopSequenceNumber : remoteStartSequenceNumber
          }
          onChange={(e) => {
            if (isStopMode) {
              setRemoteStopSequenceNumber(e.target.value);
              setSelectedConnectorId(e.target.value); // Store selected connector ID
            } else {
              setRemoteStartSequenceNumber(e.target.value);
            }
          }}
        >
          {connectors.map((item) => (
            <FormControlLabel
              key={item.sequence_number}
              value={isStopMode ? item?.id : item.sequence_number}
              control={
                <Radio
                  disabled={
                    isStopMode
                      ? !(
                          item.status === "CHARGING" ||
                          item.status === "SUSPENDEDEV" ||
                          item.status === "SUSPENDEDEVSE"
                        )
                      : !(
                          item.status === "AVAILABLE" ||
                          item.status === "PREPARING"
                        )
                  }
                />
              }
              label={`Connector ${item.sequence_number} (${item.status})`}
            />
          ))}
        </RadioGroup>
        <div className="w-100 row justify-content-center">
          <Controls.Button
            text="Submit"
            onClick={handleSubmit}
            disabled={connectors.every((item) =>
              isStopMode
                ? !(
                    item.status === "CHARGING" ||
                    item.status === "SUSPENDEDEV" ||
                    item.status === "SUSPENDEDEVSE"
                  )
                : !(item.status === "AVAILABLE" || item.status === "PREPARING")
            )}
          />
        </div>
      </FormControl>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
