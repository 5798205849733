import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TableBody, TableCell, TableRow } from '@mui/material';

const Loader = () => {
  const skeletonArray = Array(5).fill('');
  return (
    <>
    <TableBody> 
      {skeletonArray.map((item, index) => (
        <TableRow key={index}>
          <TableCell align="center" colSpan={25}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </TableCell>
        </TableRow>
        ))
      }
    </TableBody>
    </>
  );
}

export default Loader;
