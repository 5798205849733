import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {},
  inputLabel: {
    alignSelf: "center",
  },
}));

const LimitTypeSelect = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    required = false,
  } = props;

  // Mapping labels to values
  const connectorType = [
    { label: "Amp", value: "A" },
    { label: "Voltage", value: "W" },
  ];

  return (
    <FormControl
      variant="standard"
      required={required}
      className={classes.formControl}
      style={{ width: "95%" }}
      {...(error && { error: true })}
    >
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <MuiSelect
        className={classes.select}
        variant="standard"
        label={label}
        name={name}
        value={value}
        required={required}
        error={error}
        onChange={onChange}
        style={{ width: "90%" }}
      >
        {connectorType.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default LimitTypeSelect;
